/*****************************************
* ARTICLE
******************************************/
/* Search details
******************************************/
#article_search-details {
  .container-fluid {
    background: white;
  }
  button.banner {
    display: block;
    width: 100%;
    border-radius: 5px;
    font-size: 1.5em;
    text-align: left;
    .glyphicon {
      margin: 0 5px;
      transition: color .4s;
      &:hover {
        color:#cdcdcd;
      }
      &.pull-right {
        z-index:100;
      }
    }
  }
  h4 {
    margin-top: 35px;
  }
  .last-minute {
    background: #e6702e;
    color: white;
    padding: 8px;
    border-radius: 5px;
  }
  ul.form-group {
    margin-top: 25px;
  }
  ul.form-group li {
    position: relative;
    display: inline-block;
    margin: 3px;
    width: 18%;
    vertical-align: middle;
    &:last-child button {
      margin-left: 10px;
      border-radius: 5px;
      background: black;
      color: white;
      text-align: center;
      width: 80%;
    }
    .glyphicon {
      pointer-events: none;
      position: absolute;
      top: 10px;
      left: 10px;
      color: $color_grey_blue;
      &.caret {
        top: 15px;
        right: 10px;
        left: auto;
      }
    }
    select {
      padding: 0 30px;

      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
    }
  }
  ul.checkbox-list li {
    display: inline-block;
    margin: 0;
    padding: 5px;
    &:hover {
      border-radius: 5px;
      background: transparentize(white, 0.5);
    }
    input {
      margin: 0;
    }
    span {
      margin-right: 20px;
    }
  }
  .prinicpal_search_infos {
    border: $border_little-grey;
    background: white;
    span {
      margin: 10px 0;
    }
    a.pull-right {
      margin-top: 10px;
      padding: 5px 15px;
      border: $border_little-grey;
      background: transparent;
      color: black;
      &:hover {
        background: #f1f1f1;
      }
      span {
        margin: 0 0 0 3px;
      }
    }
    .glyphicon-info-sign {
      color: $color_contrast_green;
      margin-left: 3px;
      font-size: 1em;
    }
  }
}
/* Article content
******************************************/
#article_content {
  h3 {
    margin-bottom: 10px;
  }
  h3 span {
    margin-left: 10px;
    padding: 2px 10px;
    background: #666;
    color: white;
    font-size: 0.6em;
    white-space: nowrap;
  }
  .panel-heading {
    background: #8c8c8c;
    color: white;
    h4 {
      font-weight: 400;
    }
  }
  ul.article_infos li {
    display: inline-block;
    margin: 0;
    color: #9c9c9c;
    &::after {
      content: ' | ';
    }
    &:last-child::after {
      content: none;
    }
  }
  .article_description {
    h4 {
      margin-top: 30px;
      color: $color_contrast_blue;
    }
    ul li {
      margin: 0;
    }
  }
  .panel {
    position: relative;
    margin: 0;
    &:last-child {
      margin-bottom: 25px;
    }
    textarea {
      margin-bottom: 10px;
      padding: 5px;
      max-width: 100%;
      width: 100%;
      border: $border_little-grey;
      resize: vertical;
    }
    button[role='button'] {
      display: block;
      float: right;
      width: 150px;
      text-align: center;
      border-radius: 5px;
    }
  }
  .col-sm-4 {
    button[role='button'] {
      display: block;
      margin: 0 auto;
      margin: 5px 0;
      width: 100%;
      border-radius: 5px;
      text-align: center;
      font-size: 1.5em;
    }
    ul li {
      color: #959595;
      b {
        color: black;
        font-weight: 500;
      }
    }
    .panel-body {
      h4 {
        padding-bottom: 15px;
      }
      h5 {
        padding: 5px 0;
      }
      .glyphicon {
        float: left;
        margin-right: 10px;
        color: #959595;
        font-size: 1.5em;
      }
      p {
        padding-top: 5px;
        padding-left: 32px;
        color: #959595;
        font-weight: 400;
      }
      iframe {
        width: 100%;
      }
    }
  }
}
