/*****************************************
* SETTINGS
******************************************/
// Devices
$mobile: 765px;
$tablet: 992px;
$desktop: 1200px;

// Colors
$color_bg_main: #f5f5f5;
$color_grey_blue: #96a1b1;
$color_contrast_blue: #2b7cc7;
$color_contrast_green: #359d59;
$color_white: #fff;

// Other
$border_little-grey: 1px solid #cacaca;
$transition: 0.5s;

// Fonts
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.6.3');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.6.3') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.6.3') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.6.3') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');
}
.fa {
  display: inline-block;
  color: $color_grey_blue;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;

  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-plus-circle:before {
  content: '\f055';
}
.fa-power-off:before {
  content: '\f011';
}
.fa-info-circle:before {
  content: '\f05a';
}
.fa-clock-o:before {
  content: '\f017';
}
.fa-check-circle:before {
  content: '\f058';
}
.fa-user:before {
  content: '\f007';
}
.fa-envelope:before {
  content: '\f0e0';
}
.fa-phone:before {
  content: '\f095';
}
.fa-map-marker:before {
  content: '\f041';
}
.fa-facebook:before {
  content: '\f09a';
}
.fa-twitter:before {
  content: '\f099';
}
.fa-google-plus:before {
  content: '\f0d5';
}
.fa-calendar-o:before {
  content: '\f133';
}
.fa-close:before {
  content: '\f00d';
}
.glyphicon-info-sign {
  color: $color_contrast_green;
  margin-left: 5px;
}


/* Classes
******************************************/
body {
  overflow-x: hidden;
  margin-top: 100px;
  background: $color_bg_main;
  @media screen and (max-width: $mobile) {
    margin-top: 75px;
  }
}
ul.checkbox-list li {
  display: inline-block;
  margin: 2px;
  padding: 5px 0 5px 5px;
  cursor: pointer;
  &:hover {
    border-radius: 5px;
    background: transparentize(white, 0.5);
  }
  input {
    margin: 0;
    margin-right: 5px;
  }
  span {
    padding-left: 0;
  }
}
.slider {
  width: 100%;
  .slider-selection {
    background: #ed811c;
  }
}
.hide {
  display: block!important;
  visibility: hidden;
}
.nav-pills li.active a {
  background: $color_contrast_blue;
}
.well {
  border: $border_little-grey;
  border-radius: 0;
  background: white;
}

hr {
  border-top: $border_little-grey;
}

ul {
  padding: 0;
  list-style: none;
  li {
    /*margin: 15px 0;*/
  }
}
a {
  color: #545454;
  transition: $transition;
  &:hover {
    color: $color_contrast_blue;
    text-decoration: none;
    transition: $transition;
  }
}
button[role='button'] {
  padding: 8px 25px;
  border: none;
  background: $color_contrast_blue;
  color: white;
  transition: $transition;
  &:hover {
    background: lighten($color_contrast_blue, 10%);
    transition: $transition;
  }
}
a.button_more-filter {
  display: block;
  margin: 15px 0;
  padding: 15px 0;
  border-radius: 5px;
  background: #e3e3e3;
  color: #808080;
  text-align: center;
  font-weight: 200;
  font-size: 1.5em;
  &:hover {
    background: lighten(#d2d2d2, 25%);
  }
}
label {
  /*font-weight: normal;*/
}
input[type='checkbox'] {
  width: 17px;
  height: 17px;
  border: 0;
  border: 1px solid $color_grey_blue;
  border-radius: 3px;
  background: white;
  text-align: center;
  font-size: 10px;
  line-height: 1.6;

     -moz-appearance: button; /* Firefox */
  -webkit-appearance: button; /* Safari and Chrome */
          appearance: button;
  &:before {
    color: white;
    content: '✓';
  }
  &:checked {
    background: $color_contrast_blue;
  }
}
textarea {
  resize: vertical;
}
#navbar-mobile-icon {
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 1000;
  text-align: center;
  font-size: 1.8em;
  cursor: pointer;
}
.menu-filter {
  a {
    display: block;
    margin-bottom: 10px;
    margin-left: 15px;
    color: $color_contrast_blue;
    cursor: pointer;
  }
}
.menu-filter-list {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  &.open {
    visibility: visible;
    height: 100%;
    opacity: 1;
  }
}
// Navbar
nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100vw;
  .container {
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }
  .container-fluid {
    padding-top: 10px;
    height: 100px;
    border-bottom: 1px solid #d7d7d7;
    background: $color_bg_main;
    @media screen and (max-width: $mobile) {
      max-height: 75px;
      .navbar-brand {
        margin-top: 0;
      }
    }
  }
  .navbar-brand {
    img {
      width: 230px;
      @media screen and (max-width: $mobile) {
        width: 150px;
      }
    }
  }
  [class^='navbar-block-'] {
    position: relative;
    float: left;
    padding: 5px;
    width: 140px;
    background: $color_bg_main;
    color: #6e6e6e;
    text-align: center;
    @media screen and (max-width: $tablet) {
      width: auto;
    }
    &:hover {
      background: none;
      color: black;
    }
    &:last-child {
      margin-right: 0;
    }
    hr {
      margin: 5px 0;
    }
    span.fa {
      position: relative;
      right: 5px;
      clear: left;
      font-size: 1.2em;
    }
  }
  .navbar-block-lang {
    top: 15px;
    width: 110px;
    text-align: left;
    ul li {
      margin: 0;
    }
  }
  .navbar-block-connexion {
    top: 15px;
  }
  .navbar-block-candidat {
    top: 15px;
    .navbar-profil-picture {
      position: absolute;
      top: 10px;
      right: -20px;
      img {
        width: 35px;
        border-radius: 50%;
      }
      span {
        display: block;
        clear: left;
      }
    }
    &.toggle-navbar {
      margin-right: 15px;
    }
  }
  .navbar-block-recruteur {
    top: 15px;
  }
  .toggle-navbar {
    margin: 8px;
    background: transparent;
    color: black;
    &:hover {
      background: transparent;
      color: lighten(black, 75%);
    }
  }
  picture {
    position: relative;
    float:right;
    display: block;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin: 0 auto;
      max-height: 100%;
      transform: translate(-50%,-50%);
    }
  }
}
#connexionModal .modal-content {
  margin: 0 auto;
  max-width: 300px;
  input {
    margin: 10px 0;
  }
  button {
    margin-bottom: 10px;
    width: 100%;
  }
  a {
    z-index: 100;
    display: block;
    margin: 10px 0;
    width: 100%;
    color: $color_contrast_blue;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
// Menu Overlay
#navbar-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 75px;
  width: 100%;
  height: 0;
  background: $color_bg_main;
  opacity: 0;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  &.open {
    visibility: visible;
    height: 100%;
    opacity: 1;
  }
  [class^='overlay-panel'] {
    padding: 0 15px;
    border-bottom: $border_little-grey;
  }
  .overlay-panel_1 {
    button {
      display: block;
      margin: 15px 0;
      padding: 15px 0;
      width: 100%;
      border-radius: 5px;
      text-align: center;
      font-weight: 200;
      font-size: 1.5em;
      &:first-child {
        background: #34ab4b;
        &:hover {
          background: lighten(#36ab4b,25%);
        }
      }
      &:last-child {
        background: #a1a1a1;
      }
      &:hover {
        background: lighten(#a1a1a1,25%);
      }
    }
  }
  .overlay-panel_2 {
    ul {
      text-align: center;
      li {
        display: inline-block;
        margin: 10px 0 0;
        width: 32%;
        &:first-child {
          color: $color_contrast_blue;
          text-align: left;
          font-weight: bold;
        }
        &:nth-child(2) {
          color: #b0b0b0;
          text-align: center;
        }
        &:last-child {
          color: $color_contrast_blue;
          text-align: right;
        }
      }
    }
  }
  .overlay-panel_3 {
    border-bottom: 0;
    ul li {
      position: relative;
      right: 15px;
      margin: 0;
      padding: 10px 15px 10px;
      width: 105%;
      border-bottom: $border_little-grey;
      img {
        margin-right: 5px;
        width: 20px;
      }
      &:hover {
        background: lighten($color_bg_main, 25%);
      }
    }
    span.pull-right {
      margin-right: 3%;
      color: #a8a7a7;
    }
  }
}
// Footer
footer {
  .container-fluid {
    margin-bottom: 75px;
    border: $border_little-grey;
    background: white;
    color: #989898;
  }
  .container {
    position: relative;
    margin: 20px auto;
  }
  .footer-brand {
    margin: 10px 0;
    width: 15%;
    img {
      height: 20px;
    }
  }
  .footer-infos {
    margin: 10px 0;
    width: 70%;
  }
  table td {
    padding: 2px 15px;
    max-width: 200px;
  }
  .fa {
    margin-right: 5px;
  }
  .footer-socicons {
    position: absolute;
    top: -10px;
    right: 0;
    width: 15%;
    ul {
      display: inline-flex;
      float: right;
      li {
        padding: 0 5px;
      }
      .fa {
        padding-top: 25%;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #989898;
        color: white;
        text-align: center;
        font-size: 1.3em;
        transition: $transition;
        &:hover {
          background: $color_contrast_blue;
          transition: $transition;
        }
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: -50px;
    font-size: 0.8em;
    @media screen and (max-width: $mobile) {
      bottom: -70px;
      left: 0;
      width: 100%;
      text-align: center;
    }
    .menu-footer-mobile {
      margin-top: 5px;
      text-align: center;
    }
  }
}
