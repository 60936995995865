@charset "UTF-8";
/*****************************************
* SETTINGS
******************************************/
@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../fonts/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg"); }

.fa {
  display: inline-block;
  color: #96a1b1;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-plus-circle:before {
  content: '\f055'; }

.fa-power-off:before {
  content: '\f011'; }

.fa-info-circle:before {
  content: '\f05a'; }

.fa-clock-o:before {
  content: '\f017'; }

.fa-check-circle:before {
  content: '\f058'; }

.fa-user:before {
  content: '\f007'; }

.fa-envelope:before {
  content: '\f0e0'; }

.fa-phone:before {
  content: '\f095'; }

.fa-map-marker:before {
  content: '\f041'; }

.fa-facebook:before {
  content: '\f09a'; }

.fa-twitter:before {
  content: '\f099'; }

.fa-google-plus:before {
  content: '\f0d5'; }

.fa-calendar-o:before {
  content: '\f133'; }

.fa-close:before {
  content: '\f00d'; }

.glyphicon-info-sign {
  color: #359d59;
  margin-left: 5px; }

/* Classes
******************************************/
body {
  overflow-x: hidden;
  margin-top: 100px;
  background: #f5f5f5; }
  @media screen and (max-width: 765px) {
    body {
      margin-top: 75px; } }

ul.checkbox-list li {
  display: inline-block;
  margin: 2px;
  padding: 5px 0 5px 5px;
  cursor: pointer; }
  ul.checkbox-list li:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.5); }
  ul.checkbox-list li input {
    margin: 0;
    margin-right: 5px; }
  ul.checkbox-list li span {
    padding-left: 0; }

.slider {
  width: 100%; }
  .slider .slider-selection {
    background: #ed811c; }

.hide {
  display: block !important;
  visibility: hidden; }

.nav-pills li.active a {
  background: #2b7cc7; }

.well {
  border: 1px solid #cacaca;
  border-radius: 0;
  background: white; }

hr {
  border-top: 1px solid #cacaca; }

ul {
  padding: 0;
  list-style: none; }
  ul li {
    /*margin: 15px 0;*/ }

a {
  color: #545454;
  transition: 0.5s; }
  a:hover {
    color: #2b7cc7;
    text-decoration: none;
    transition: 0.5s; }

button[role='button'] {
  padding: 8px 25px;
  border: none;
  background: #2b7cc7;
  color: white;
  transition: 0.5s; }
  button[role='button']:hover {
    background: #4d95d8;
    transition: 0.5s; }

a.button_more-filter {
  display: block;
  margin: 15px 0;
  padding: 15px 0;
  border-radius: 5px;
  background: #e3e3e3;
  color: #808080;
  text-align: center;
  font-weight: 200;
  font-size: 1.5em; }
  a.button_more-filter:hover {
    background: white; }

label {
  /*font-weight: normal;*/ }

input[type='checkbox'] {
  width: 17px;
  height: 17px;
  border: 0;
  border: 1px solid #96a1b1;
  border-radius: 3px;
  background: white;
  text-align: center;
  font-size: 10px;
  line-height: 1.6;
  -moz-appearance: button;
  /* Firefox */
  -webkit-appearance: button;
  /* Safari and Chrome */
  appearance: button; }
  input[type='checkbox']:before {
    color: white;
    content: '✓'; }
  input[type='checkbox']:checked {
    background: #2b7cc7; }

textarea {
  resize: vertical; }

#navbar-mobile-icon {
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 1000;
  text-align: center;
  font-size: 1.8em;
  cursor: pointer; }

.menu-filter a {
  display: block;
  margin-bottom: 10px;
  margin-left: 15px;
  color: #2b7cc7;
  cursor: pointer; }

.menu-filter-list {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s; }
  .menu-filter-list.open {
    visibility: visible;
    height: 100%;
    opacity: 1; }

nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 100vw; }
  @media screen and (max-width: 992px) {
    nav .container {
      width: 100%; } }
  nav .container-fluid {
    padding-top: 10px;
    height: 100px;
    border-bottom: 1px solid #d7d7d7;
    background: #f5f5f5; }
    @media screen and (max-width: 765px) {
      nav .container-fluid {
        max-height: 75px; }
        nav .container-fluid .navbar-brand {
          margin-top: 0; } }
  nav .navbar-brand img {
    width: 230px; }
    @media screen and (max-width: 765px) {
      nav .navbar-brand img {
        width: 150px; } }
  nav [class^='navbar-block-'] {
    position: relative;
    float: left;
    padding: 5px;
    width: 140px;
    background: #f5f5f5;
    color: #6e6e6e;
    text-align: center; }
    @media screen and (max-width: 992px) {
      nav [class^='navbar-block-'] {
        width: auto; } }
    nav [class^='navbar-block-']:hover {
      background: none;
      color: black; }
    nav [class^='navbar-block-']:last-child {
      margin-right: 0; }
    nav [class^='navbar-block-'] hr {
      margin: 5px 0; }
    nav [class^='navbar-block-'] span.fa {
      position: relative;
      right: 5px;
      clear: left;
      font-size: 1.2em; }
  nav .navbar-block-lang {
    top: 15px;
    width: 110px;
    text-align: left; }
    nav .navbar-block-lang ul li {
      margin: 0; }
  nav .navbar-block-connexion {
    top: 15px; }
  nav .navbar-block-candidat {
    top: 15px; }
    nav .navbar-block-candidat .navbar-profil-picture {
      position: absolute;
      top: 10px;
      right: -20px; }
      nav .navbar-block-candidat .navbar-profil-picture img {
        width: 35px;
        border-radius: 50%; }
      nav .navbar-block-candidat .navbar-profil-picture span {
        display: block;
        clear: left; }
    nav .navbar-block-candidat.toggle-navbar {
      margin-right: 15px; }
  nav .navbar-block-recruteur {
    top: 15px; }
  nav .toggle-navbar {
    margin: 8px;
    background: transparent;
    color: black; }
    nav .toggle-navbar:hover {
      background: transparent;
      color: #bfbfbf; }
  nav picture {
    position: relative;
    float: right;
    display: block;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 50%; }
    nav picture img {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      margin: 0 auto;
      max-height: 100%;
      transform: translate(-50%, -50%); }

#connexionModal .modal-content {
  margin: 0 auto;
  max-width: 300px; }
  #connexionModal .modal-content input {
    margin: 10px 0; }
  #connexionModal .modal-content button {
    margin-bottom: 10px;
    width: 100%; }
  #connexionModal .modal-content a {
    z-index: 100;
    display: block;
    margin: 10px 0;
    width: 100%;
    color: #2b7cc7;
    text-align: center;
    text-decoration: underline;
    cursor: pointer; }

#navbar-overlay-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  visibility: hidden;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 75px;
  width: 100%;
  height: 0;
  background: #f5f5f5;
  opacity: 0;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s; }
  #navbar-overlay-menu.open {
    visibility: visible;
    height: 100%;
    opacity: 1; }
  #navbar-overlay-menu [class^='overlay-panel'] {
    padding: 0 15px;
    border-bottom: 1px solid #cacaca; }
  #navbar-overlay-menu .overlay-panel_1 button {
    display: block;
    margin: 15px 0;
    padding: 15px 0;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    font-weight: 200;
    font-size: 1.5em; }
    #navbar-overlay-menu .overlay-panel_1 button:first-child {
      background: #34ab4b; }
      #navbar-overlay-menu .overlay-panel_1 button:first-child:hover {
        background: #87d996; }
    #navbar-overlay-menu .overlay-panel_1 button:last-child {
      background: #a1a1a1; }
    #navbar-overlay-menu .overlay-panel_1 button:hover {
      background: #e1e1e1; }
  #navbar-overlay-menu .overlay-panel_2 ul {
    text-align: center; }
    #navbar-overlay-menu .overlay-panel_2 ul li {
      display: inline-block;
      margin: 10px 0 0;
      width: 32%; }
      #navbar-overlay-menu .overlay-panel_2 ul li:first-child {
        color: #2b7cc7;
        text-align: left;
        font-weight: bold; }
      #navbar-overlay-menu .overlay-panel_2 ul li:nth-child(2) {
        color: #b0b0b0;
        text-align: center; }
      #navbar-overlay-menu .overlay-panel_2 ul li:last-child {
        color: #2b7cc7;
        text-align: right; }
  #navbar-overlay-menu .overlay-panel_3 {
    border-bottom: 0; }
    #navbar-overlay-menu .overlay-panel_3 ul li {
      position: relative;
      right: 15px;
      margin: 0;
      padding: 10px 15px 10px;
      width: 105%;
      border-bottom: 1px solid #cacaca; }
      #navbar-overlay-menu .overlay-panel_3 ul li img {
        margin-right: 5px;
        width: 20px; }
      #navbar-overlay-menu .overlay-panel_3 ul li:hover {
        background: white; }
    #navbar-overlay-menu .overlay-panel_3 span.pull-right {
      margin-right: 3%;
      color: #a8a7a7; }

footer .container-fluid {
  margin-bottom: 75px;
  border: 1px solid #cacaca;
  background: white;
  color: #989898; }

footer .container {
  position: relative;
  margin: 20px auto; }

footer .footer-brand {
  margin: 10px 0;
  width: 15%; }
  footer .footer-brand img {
    height: 20px; }

footer .footer-infos {
  margin: 10px 0;
  width: 70%; }

footer table td {
  padding: 2px 15px;
  max-width: 200px; }

footer .fa {
  margin-right: 5px; }

footer .footer-socicons {
  position: absolute;
  top: -10px;
  right: 0;
  width: 15%; }
  footer .footer-socicons ul {
    display: inline-flex;
    float: right; }
    footer .footer-socicons ul li {
      padding: 0 5px; }
    footer .footer-socicons ul .fa {
      padding-top: 25%;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #989898;
      color: white;
      text-align: center;
      font-size: 1.3em;
      transition: 0.5s; }
      footer .footer-socicons ul .fa:hover {
        background: #2b7cc7;
        transition: 0.5s; }

footer .copyright {
  position: absolute;
  bottom: -50px;
  font-size: 0.8em; }
  @media screen and (max-width: 765px) {
    footer .copyright {
      bottom: -70px;
      left: 0;
      width: 100%;
      text-align: center; } }
  footer .copyright .menu-footer-mobile {
    margin-top: 5px;
    text-align: center; }

/*****************************************
* HOMEPAGE
******************************************/
/* Homepage Cover
******************************************/
#homepage_cover .container-fluid {
  min-height: 500px;
  background-image: url("../img/home_background.jpg");
  background-size: cover; }

#homepage_cover .container {
  position: relative;
  margin: 100px auto;
  padding: 5px 25px;
  background: rgba(255, 255, 255, 0.25); }
  @media screen and (min-width: 765px) and (max-width: 992px) {
    #homepage_cover .container {
      width: 100%; } }

#homepage_cover .homepage_cover_label {
  position: absolute;
  top: -20px;
  padding: 5px 20px;
  background: #e6702e;
  color: white; }

#homepage_cover .homepage_cover_result {
  position: absolute;
  top: -35px;
  right: 0;
  color: white; }
  #homepage_cover .homepage_cover_result b {
    padding: 0 2px;
    font-size: 25px; }

#homepage_cover .homepage_cover_search {
  position: absolute;
  right: 0;
  bottom: -35px;
  left: 0;
  z-index: 1;
  text-align: center; }
  #homepage_cover .homepage_cover_search button {
    width: 250px; }

#homepage_cover .homepage_cover_lastminute li {
  margin: 25px 0 17px 20px;
  width: 215px;
  border-radius: 5px;
  background: white; }
  #homepage_cover .homepage_cover_lastminute li span {
    margin-right: 5px; }
  #homepage_cover .homepage_cover_lastminute li input {
    position: relative;
    right: 25px; }
  #homepage_cover .homepage_cover_lastminute li .glyphicon-info-sign {
    vertical-align: middle;
    margin: 0;
    font-size: 1.2em; }

#homepage_cover .col-md-3,
#homepage_cover .col-md-2 {
  padding: 0 5px; }
  @media screen and (max-width: 765px) {
    #homepage_cover .col-md-3,
    #homepage_cover .col-md-2 {
      width: 100%; } }
  #homepage_cover .col-md-3 .well,
  #homepage_cover .col-md-2 .well {
    overflow: hidden;
    padding: 2px 10px;
    min-height: 310px;
    background: rgba(255, 255, 255, 0.82);
    color: #757575; }
  #homepage_cover .col-md-3 h4,
  #homepage_cover .col-md-2 h4 {
    margin: 15px 0;
    color: #505050;
    font-size: 1.2em; }
  #homepage_cover .col-md-3 hr,
  #homepage_cover .col-md-2 hr {
    margin-left: -50px;
    width: 200%; }
  #homepage_cover .col-md-3 .panel_1 ul li select,
  #homepage_cover .col-md-2 .panel_1 ul li select {
    color: #96a1b1;
    text-indent: 25px; }
  #homepage_cover .col-md-3 .panel_1 ul li .form-control-feedback,
  #homepage_cover .col-md-2 .panel_1 ul li .form-control-feedback {
    left: 0;
    color: #96a1b1;
    font-size: 15px; }
  #homepage_cover .col-md-3 .panel_2 ul li,
  #homepage_cover .col-md-3 .panel_3 ul li,
  #homepage_cover .col-md-2 .panel_2 ul li,
  #homepage_cover .col-md-2 .panel_3 ul li {
    margin: 0;
    padding: 8px;
    width: 100%;
    border-bottom: none;
    border-radius: 5px; }
    #homepage_cover .col-md-3 .panel_2 ul li:last-child a,
    #homepage_cover .col-md-3 .panel_3 ul li:last-child a,
    #homepage_cover .col-md-2 .panel_2 ul li:last-child a,
    #homepage_cover .col-md-2 .panel_3 ul li:last-child a {
      color: #2b7cc7; }
      #homepage_cover .col-md-3 .panel_2 ul li:last-child a:hover,
      #homepage_cover .col-md-3 .panel_3 ul li:last-child a:hover,
      #homepage_cover .col-md-2 .panel_2 ul li:last-child a:hover,
      #homepage_cover .col-md-2 .panel_3 ul li:last-child a:hover {
        text-decoration: underline; }
    #homepage_cover .col-md-3 .panel_2 ul li:last-child .fa,
    #homepage_cover .col-md-3 .panel_3 ul li:last-child .fa,
    #homepage_cover .col-md-2 .panel_2 ul li:last-child .fa,
    #homepage_cover .col-md-2 .panel_3 ul li:last-child .fa {
      padding-right: 10px;
      color: #2b7cc7;
      font-size: 1.3em; }
    #homepage_cover .col-md-3 .panel_2 ul li span,
    #homepage_cover .col-md-3 .panel_3 ul li span,
    #homepage_cover .col-md-2 .panel_2 ul li span,
    #homepage_cover .col-md-2 .panel_3 ul li span {
      color: #96a1b1; }
  #homepage_cover .col-md-3 .panel_2 hr,
  #homepage_cover .col-md-3 .panel_3 hr,
  #homepage_cover .col-md-2 .panel_2 hr,
  #homepage_cover .col-md-2 .panel_3 hr {
    margin-bottom: 15px; }
  #homepage_cover .col-md-3 .panel_3 ul li span,
  #homepage_cover .col-md-2 .panel_3 ul li span {
    margin-left: 6px; }
  #homepage_cover .col-md-3 .panel_3 ul li:last-child input,
  #homepage_cover .col-md-2 .panel_3 ul li:last-child input {
    visibility: hidden; }
  #homepage_cover .col-md-3 .panel_3 ul li:last-child span,
  #homepage_cover .col-md-2 .panel_3 ul li:last-child span {
    position: relative;
    top: 2px;
    color: #2b7cc7; }
  #homepage_cover .col-md-3 .panel_3 ul li .form-control-feedback,
  #homepage_cover .col-md-2 .panel_3 ul li .form-control-feedback {
    left: 15px;
    margin-top: 5px;
    font-size: 1.4em; }
  #homepage_cover .col-md-3 img,
  #homepage_cover .col-md-2 img {
    max-width: 25px; }
  #homepage_cover .col-md-3 input[type='checkbox'],
  #homepage_cover .col-md-2 input[type='checkbox'] {
    margin-right: 7px; }

/* Homepage FAQ
******************************************/
#homepage_faq .container {
  margin: 50px auto 100px; }

#homepage_faq ul.nav-pills li {
  margin: 0;
  padding: 0 12px;
  width: 25%; }
  #homepage_faq ul.nav-pills li:first-child {
    padding-left: 0; }
  #homepage_faq ul.nav-pills li:last-child {
    padding-right: 0; }
  #homepage_faq ul.nav-pills li.active a {
    background: white; }
  #homepage_faq ul.nav-pills li a {
    overflow: hidden;
    border: 1px solid #cacaca;
    border-bottom: 0;
    border-radius: 0;
    background: #e2e2e2;
    color: #838383;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.8s; }
    #homepage_faq ul.nav-pills li a span:last-child {
      width: 125px; }
    #homepage_faq ul.nav-pills li a:hover {
      background: white;
      transition: 0.2s; }
  #homepage_faq ul.nav-pills li .fa {
    margin-right: 5px;
    color: #4d4d4d;
    font-size: 1.2em; }

#homepage_faq .tab-content {
  margin: 0;
  min-height: 200px;
  border: 1px solid #cacaca;
  background: white; }

#homepage_faq .tab-pane {
  padding: 30px 45px; }
  #homepage_faq .tab-pane h3 {
    margin: 0;
    padding: 12px 0 25px; }

/*****************************************
* PRINCIPAL
******************************************/
/* Search bar
******************************************/
.white-search #principal_search .tab-content, .white-search #principal_search_lastminute {
  color: #333333 !important;
  background: white !important; }

#principal_search {
  border-bottom: 1px solid #cacaca; }
  #principal_search .glyphicon-info-sign {
    color: #359d59;
    margin-left: 3px;
    font-size: 1em; }
  #principal_search .container-fluid {
    padding-top: 20px;
    background: white; }
  #principal_search .container {
    position: relative; }
  #principal_search ul.nav li {
    margin: 0 10px 0 0;
    border-radius: 5px 5px 0 0; }
    #principal_search ul.nav li a {
      margin-bottom: 5px;
      padding: 0;
      border-bottom: 3px solid #e37f09;
      border-radius: 5px 5px 0 0;
      background: white;
      color: black;
      text-transform: uppercase; }
    #principal_search ul.nav li:first-child a {
      margin-right: 10px;
      border-bottom: 3px solid #2b7cc7; }
  #principal_search span.pull-right.result {
    position: absolute;
    top: 0;
    right: 15px;
    text-align: right; }
    #principal_search span.pull-right.result b {
      padding: 0 2px;
      font-size: 25px; }
  #principal_search .tab-content #principal_search_lastminute {
    background: #e37f09; }
  #principal_search .tab-pane {
    padding: 10px; }
  #principal_search .tab-content {
    position: relative;
    background: #2b7cc7;
    color: white; }
    #principal_search .tab-content h4 {
      font-weight: 100em; }
    #principal_search .tab-content ul.form-group {
      margin: 0;
      height: 110px; }
      #principal_search .tab-content ul.form-group li {
        display: inline-block;
        margin: 3px;
        width: 18%; }
        #principal_search .tab-content ul.form-group li:last-child button {
          position: relative;
          top: -26px;
          margin-left: 10px;
          border-radius: 5px;
          background: black;
          color: white; }
      #principal_search .tab-content ul.form-group select {
        padding-right: 25px;
        padding-left: 30px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; }
      #principal_search .tab-content ul.form-group .glyphicon {
        position: relative;
        top: -25px;
        left: 9px;
        color: #96a1b1; }
        #principal_search .tab-content ul.form-group .glyphicon.caret {
          top: -20px;
          left: -10px; }
    #principal_search .tab-content ul.checkbox-list {
      position: absolute;
      bottom: 2px;
      margin: 0; }
      #principal_search .tab-content ul.checkbox-list li {
        padding: 5px; }
  #principal_search .prinicpal_search_infos span {
    margin: 10px 0; }
  #principal_search .prinicpal_search_infos a.pull-right {
    margin-top: 10px;
    padding: 5px 15px;
    border: 1px solid #cacaca;
    background: transparent;
    color: black; }
    #principal_search .prinicpal_search_infos a.pull-right:hover {
      background: #f1f1f1; }
    #principal_search .prinicpal_search_infos a.pull-right span {
      margin: 0 0 0 3px; }
  #principal_search select {
    color: #96a1b1; }

/* Prinicpal Output
******************************************/
#principal_output {
  margin-top: 25px;
  margin-bottom: 75px; }
  #principal_output .prinicpal_output_filters label {
    font-weight: normal; }
  #principal_output .glyphicon-info-sign {
    color: #359d59;
    margin-left: 3px;
    font-size: 1em; }
  #principal_output [class^='article-part'] {
    position: relative;
    float: left;
    padding: 12px;
    width: 33.3%;
    height: 100%; }
  #principal_output .article {
    margin-bottom: 30px;
    width: 100%;
    height: 150px;
    border: 1px solid #d7d7d7;
    background: white;
    transition: background 0.5s; }
    #principal_output .article:hover {
      background: #f0f0f0; }
  #principal_output .article-origin {
    margin-bottom: 8px;
    border-bottom: 1px solid #cacaca;
    color: #96a1b1;
    font-weight: 500; }
  #principal_output .article-part1 {
    border-right: none; }
    #principal_output .article-part1 > span {
      position: absolute;
      top: -10px;
      right: 33%;
      padding: 2px 25px;
      background: #96a1b1;
      color: white; }
    #principal_output .article-part1 .article-content {
      display: -webkit-box;
      overflow: hidden;
      max-height: 2.6;
      text-overflow: ellipsis;
      font-size: 1.2em;
      line-height: 1.3;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
    #principal_output .article-part1 .article-infos {
      position: absolute;
      bottom: 0;
      margin-bottom: 5px; }
      #principal_output .article-part1 .article-infos span {
        color: #96a1b1; }
      #principal_output .article-part1 .article-infos > span:first-child::after,
      #principal_output .article-part1 .article-infos > span:nth-child(2)::after {
        padding: 0 3px;
        content: ' | '; }
      #principal_output .article-part1 .article-infos > span:last-child {
        color: #2b7cc7; }
    #principal_output .article-part1 .media {
      margin: 0;
      padding-bottom: 10px;
      width: 100%;
      height: 100%;
      display: flex; }
      #principal_output .article-part1 .media .media-body {
        margin: auto 0; }
      #principal_output .article-part1 .media ul li {
        margin: 0; }
    #principal_output .article-part1 picture {
      position: relative;
      display: block;
      overflow: hidden;
      width: 80px;
      margin: auto 0;
      margin-right: 15px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #8b8b8b;
      flex-shrink: 0; }
      #principal_output .article-part1 picture img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        transform: translate(-50%, -50%); }
  #principal_output .article-part2 {
    border-right: none;
    border-left: none; }
    #principal_output .article-part2 > div {
      color: #96a1b1;
      font-weight: 500; }
    #principal_output .article-part2 span {
      padding: 2px 4px;
      background: #96a1b1;
      color: white;
      white-space: nowrap;
      line-height: 2em; }
      #principal_output .article-part2 span.lang {
        background: #d42322; }
  #principal_output .article-part3 {
    border-left: none;
    /*button {
      float: right;
      background: none;
      color: $color_grey_blue;
      z-index: 100;
      width: 35px;
      height: 35px;
      border: 1px solid $color_grey_blue;
      padding: 0;
      border-radius: 50%;
      &:hover {
        color:$color_contrast_blue;
        background: white;
      }
    }*/ }
    #principal_output .article-part3 .article-origin {
      text-align: center; }
    #principal_output .article-part3 hr {
      margin: 0 auto;
      margin-bottom: 8px;
      width: 125px; }
    #principal_output .article-part3 .article-label {
      margin: auto;
      padding: 4px 6px;
      width: 100px;
      background: #f56a11;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-weight: 200;
      font-size: 0.8em;
      margin-bottom: 5px; }
      #principal_output .article-part3 .article-label.purple {
        background: #9e2ab6; }
      #principal_output .article-part3 .article-label.blue {
        background: #243682; }
      #principal_output .article-part3 .article-label.red {
        background: #d22; }
    #principal_output .article-part3 .article-pourcent {
      margin: 5px 0;
      padding-left: 5px;
      text-align: center;
      font-weight: 700;
      font-size: 1.8em; }
  #principal_output a[role='button'] {
    position: relative;
    margin-top: 5px;
    border: 1px solid #cacaca;
    background: white;
    color: #96a1b1;
    text-align: center; }
    #principal_output a[role='button']:hover {
      background: #f2f2f2; }

.col-md-3 h4 {
  margin-bottom: 15px;
  margin-left: 15px;
  color: #2b7cc7; }
  .col-md-3 h4.km-slider {
    font-size: .95em;
    margin: 0;
    margin-bottom: 20px; }
    .col-md-3 h4.km-slider .glyphicon {
      margin-right: 5px; }

.col-md-3 .access {
  margin-left: 15px;
  color: #2b7cc7; }

.col-md-3 ul {
  margin-bottom: 35px; }

.col-md-3 ul.checkbox-list li {
  position: relative;
  margin: 0;
  padding: 10px 15px 10px;
  width: 100%;
  border-bottom: 1px solid #cacaca; }
  .col-md-3 ul.checkbox-list li:hover {
    background: white; }
  .col-md-3 ul.checkbox-list li:last-child a {
    color: #2b7cc7; }
  .col-md-3 ul.checkbox-list li span {
    position: relative;
    top: 4px; }
  .col-md-3 ul.checkbox-list li[data-toggle='modal'] {
    color: #2b7cc7; }
  .col-md-3 ul.checkbox-list li input {
    position: relative;
    top: 2px;
    margin-right: 5px; }
  .col-md-3 ul.checkbox-list li img {
    margin-right: 4px;
    max-width: 20px; }
  .col-md-3 ul.checkbox-list li .glyphicon {
    color: #2b7cc7;
    font-size: 1.4em;
    margin-right: 5px; }
  .col-md-3 ul.checkbox-list li .blue {
    color: #2b7cc7;
    vertical-align: top; }

.col-md-3 a[role='button'] {
  padding: 15px;
  border: 1px solid #cacaca;
  background: white;
  color: #96a1b1; }
  .col-md-3 a[role='button']:hover {
    background: #f5f5f5; }

.col-md-3 .menu-filter-list ul li:last-child a {
  color: black; }

.col-md-3 .form-control-feedback {
  left: 30px;
  color: #96a1b1;
  font-size: 1.5em; }

#new-account h3 {
  position: relative;
  left: 20px;
  margin-bottom: 25px; }

#new-account ul.nav-tabs {
  position: relative;
  top: 2px;
  left: 15px;
  z-index: 10;
  border: 0; }
  #new-account ul.nav-tabs li {
    margin: 0 5px;
    padding: 0;
    border: 1px solid #cacaca;
    border-radius: 5px 5px 0 0;
    background: white; }
    #new-account ul.nav-tabs li.active {
      border-bottom: 2px solid white; }
      #new-account ul.nav-tabs li.active a {
        color: #2b7cc7;
        background: white; }
    #new-account ul.nav-tabs li:first-child {
      margin-left: 0; }
    #new-account ul.nav-tabs li a {
      padding: 5px 20px;
      border: 0;
      background: #2b7cc7;
      color: #f5f5f5;
      margin: 0; }

#new-account .col-sm-6 {
  position: relative;
  margin-bottom: 30px; }
  #new-account .col-sm-6 .civil {
    margin-bottom: 15px; }
    #new-account .col-sm-6 .civil span {
      margin-right: 15px;
      font-weight: bold; }
    #new-account .col-sm-6 .civil input {
      margin: 0 3px; }
    #new-account .col-sm-6 .civil label {
      margin-right: 10px; }
  #new-account .col-sm-6 .form-group.half-input {
    min-height: 85px; }
    #new-account .col-sm-6 .form-group.half-input .form-control {
      float: left;
      width: 45%; }
      #new-account .col-sm-6 .form-group.half-input .form-control:last-child {
        float: right; }
  #new-account .col-sm-6 .doc {
    margin-bottom: 15px; }
    #new-account .col-sm-6 .doc a {
      color: #2b7cc7; }
  #new-account .col-sm-6 .glyphicon.glyphicon-ok {
    color: #359d59; }
  #new-account .col-sm-6 button[role='button'] {
    position: absolute;
    right: 0;
    bottom: 4px;
    left: 0;
    display: block;
    margin: auto;
    width: 50%;
    max-width: 200px;
    border-radius: 5px;
    background: #359d59;
    text-align: center;
    font-weight: bold; }
    @media screen and (max-width: 765px) {
      #new-account .col-sm-6 button[role='button'] {
        bottom: -20px; } }
    #new-account .col-sm-6 button[role='button']:hover {
      background: #7ed39c; }
  #new-account .col-sm-6.part1 {
    padding-right: 0; }
  #new-account .col-sm-6.part2 {
    padding-left: 0; }
    #new-account .col-sm-6.part2 .well {
      position: relative;
      padding: 40px;
      border-left: 0; }
    #new-account .col-sm-6.part2 a,
    #new-account .col-sm-6.part2 span {
      font-size: 1.2em; }
    #new-account .col-sm-6.part2 button[role='button'] {
      bottom: -18px;
      font-size: 1em; }
    #new-account .col-sm-6.part2 .form-control {
      background: none; }

/*****************************************
* CREATE PROFILE PAGE
******************************************/
/* Steps
******************************************/
#create-profile_steps {
  margin-bottom: 25px;
  border: 1px solid #cacaca; }
  #create-profile_steps .container-fluid {
    background: white; }
  #create-profile_steps h2 {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 200; }
  #create-profile_steps hr {
    position: relative;
    width: 72%;
    border-bottom: 2px solid #d6d6d6; }
  #create-profile_steps ul li {
    position: relative;
    top: -35px;
    left: 15px;
    display: inline-block;
    float: left;
    margin: auto;
    padding: 0;
    width: 24%;
    text-align: center; }
    #create-profile_steps ul li div:first-child {
      margin: auto;
      padding-top: 3px;
      width: 30px;
      height: 30px;
      border: 3px solid #d6d6d6;
      border-radius: 50%;
      background: white; }
    #create-profile_steps ul li div:last-child {
      padding-top: 5px;
      color: #a8a8a8; }
    #create-profile_steps ul li.active div:first-child {
      background: #2b7cc7;
      color: white; }

/* Edit part
******************************************/
#create-profile_edit .notification {
  padding-right: 50px;
  padding-left: 50px; }
  @media screen and (max-width: 765px) {
    #create-profile_edit .notification {
      text-align: center; } }
  #create-profile_edit .notification [class^='col-sm-'] {
    padding: 15px 0; }
  #create-profile_edit .notification .col-sm-7 {
    padding: 15px;
    padding-left: 50px; }
    @media (max-width: 992px) {
      #create-profile_edit .notification .col-sm-7 {
        padding-left: 0; } }
  #create-profile_edit .notification .fa-envelope {
    padding: 0 25px;
    float: right;
    color: #737373;
    font-size: 4em; }
    @media (max-width: 765px) {
      #create-profile_edit .notification .fa-envelope {
        float: none; } }
  #create-profile_edit .notification input {
    margin-top: 5px;
    width: 75px;
    border: 1px solid #cacaca; }
  #create-profile_edit .notification button[role='button'] {
    margin-top: 5px;
    padding: 5px 10px;
    padding-right: 12px;
    background: #359d59;
    font-size: .8em; }
    #create-profile_edit .notification button[role='button']:hover {
      background: #7ed39c; }
  #create-profile_edit .notification p {
    margin: 0;
    padding: 0; }

#create-profile_edit .page {
  margin-top: 50px; }
  #create-profile_edit .page h2 {
    margin-bottom: 50px;
    font-weight: 400; }
  #create-profile_edit .page > span {
    position: relative;
    bottom: 5px;
    padding: 8px 25px;
    border: 1px solid #cacaca;
    border-bottom: 1px solid white;
    border-radius: 5px 5px 0 0;
    background: white;
    color: #2b7cc7; }
  #create-profile_edit .page .well {
    position: relative; }
    #create-profile_edit .page .well .glyphicon-chevron-right,
    #create-profile_edit .page .well .glyphicon-chevron-left {
      position: absolute;
      top: 45%;
      padding: 10px;
      border: 4px solid #2b7cc7;
      border-radius: 50%;
      color: #2b7cc7;
      font-size: 2em;
      transition: 0.5s; }
      #create-profile_edit .page .well .glyphicon-chevron-right:hover,
      #create-profile_edit .page .well .glyphicon-chevron-left:hover {
        border: 4px solid #8bbae6;
        color: #8bbae6;
        transition: 0.5s; }
      #create-profile_edit .page .well .glyphicon-chevron-right.disabled,
      #create-profile_edit .page .well .glyphicon-chevron-left.disabled {
        border: 4px solid #d1d1d1;
        color: #d1d1d1;
        cursor: default; }
      #create-profile_edit .page .well .glyphicon-chevron-right.glyphicon-chevron-right,
      #create-profile_edit .page .well .glyphicon-chevron-left.glyphicon-chevron-right {
        right: -70px; }
      #create-profile_edit .page .well .glyphicon-chevron-right.glyphicon-chevron-left,
      #create-profile_edit .page .well .glyphicon-chevron-left.glyphicon-chevron-left {
        left: -70px; }
    #create-profile_edit .page .well .col-md-6 {
      padding: 30px;
      min-height: 650px; }
      #create-profile_edit .page .well .col-md-6:first-child {
        border-right: 1px solid #cacaca; }
        @media screen and (max-width: 992px) {
          #create-profile_edit .page .well .col-md-6:first-child {
            border-right: 0; } }
    #create-profile_edit .page .well .media img {
      float: left;
      width: 100px;
      height: 100px;
      border: 1px solid #cacaca;
      border-radius: 50%; }
    #create-profile_edit .page .well .media h3 {
      padding-left: 15px;
      font-size: 1.3em; }
    #create-profile_edit .page .well .media label {
      margin-left: 15px; }
    #create-profile_edit .page .well .civil {
      margin: 25px 0; }
      #create-profile_edit .page .well .civil span {
        margin-right: 15px;
        font-weight: bold; }
      #create-profile_edit .page .well .civil input {
        margin-right: 3px; }
      #create-profile_edit .page .well .civil label {
        margin-right: 15px;
        font-weight: 400; }
    #create-profile_edit .page .well .form-group {
      margin-top: 25px; }
    #create-profile_edit .page .well .form-group.address {
      margin-top: 25px; }
      #create-profile_edit .page .well .form-group.address .form-control {
        margin-bottom: 15px; }
    #create-profile_edit .page .well .form-group input[placeholder='Code Postal'],
    #create-profile_edit .page .well .form-group input[placeholder='Localité'] {
      float: left;
      width: 48%; }
    #create-profile_edit .page .well .form-group input[placeholder='Code Postal'] {
      margin-right: 15px; }
    #create-profile_edit .page .well select.form-control {
      color: #9f9f9f; }
    #create-profile_edit .page .well .form-group.question input {
      /*margin-top: 10px;
          margin-right: 3px;*/ }
    #create-profile_edit .page .well .form-group.question label {
      margin-right: 15px; }
    #create-profile_edit .page .well .form-group.question select {
      float: right;
      width: 33%; }
    #create-profile_edit .page .well .form-group.question input[type="date"]  {
      width: 100px; }
    #create-profile_edit .page .well .button-green {
      position: absolute;
      right: 30px;
      bottom: 15px;
      width: 33%;
      border-radius: 3px;
      background: #359d59;
      text-align: center; }

#create-profile_steps button {
  width: 100%;
  border-radius: 5px;
  background: #359d59;
  text-transform: uppercase; }

#create-profile_steps a {
  display: block;
  margin-bottom: 15px;
  color: #359d59;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase; }

#create-profile_steps .steps {
  float: left;
  width: 100%; }

#create-profile_steps .button-float {
  margin-bottom: 10px; }

@media screen and (min-width: 992px) {
  #create-profile_steps .container {
    position: relative; }
  #create-profile_steps .button-float {
    position: absolute;
    right: 20px;
    bottom: 40px; }
  #create-profile_steps .steps {
    float: left;
    width: 75%; } }

#create-profile_edit .link-style {
  padding-left: 30px !important;
  border-bottom: 0 !important; }
  #create-profile_edit .link-style a {
    text-decoration: underline;
    color: #2b7cc7; }
    #create-profile_edit .link-style a i {
      margin-right: 5px;
      color: #2b7cc7;
      font-size: 17px; }

#create-profile_edit .km {
  border: 0 !important; }
  #create-profile_edit .km h5 {
    display: initial; }
  #create-profile_edit .km h5, #create-profile_edit .km i {
    color: #2b7cc7;
    font-weight: bold; }

#create-profile_edit .page .well ul li button[role='button'] {
  position: static;
  float: right;
  padding: 0;
  width: 125px;
  background: transparent;
  color: #359d59; }
  #create-profile_edit .page .well ul li button[role='button'] span.text {
    text-decoration: underline;
    text-transform: uppercase; }
  #create-profile_edit .page .well ul li button[role='button'] span.glyphicon {
    float: left;
    font-size: 1.3em; }
  #create-profile_edit .page .well ul li button[role='button']:hover {
    color: #6bcd8d; }

#create-profile_edit ul {
  margin-bottom: 50px; }

#create-profile_edit .page .well .panel-heading button[role='button'] {
  position: static;
  display: block;
  margin: 0;
  padding: 10px 17px;
  width: 100%;
  border-radius: 0;
  background: #2b7cc7;
  color: #f5f5f5;
  text-align: left; }
  #create-profile_edit .page .well .panel-heading button[role='button']:hover {
    background: #4d95d8; }

#create-profile_edit .page .well .panel-heading:nth-child(2) {
  padding: 0; }
  #create-profile_edit .page .well .panel-heading:nth-child(2) .glyphicon {
    margin-right: 10px;
    padding: 0; }

#create-profile_edit .page .well button[role='button'].next-step {
  position: relative;
  right: 0;
  margin-top: 10px;
  width: 300px;
  text-transform: uppercase; }
  @media screen and (max-width: 765px) {
    #create-profile_edit .page .well button[role='button'].next-step {
      width: 100%; } }
  #create-profile_edit .page .well button[role='button'].next-step:hover {
    background: #58c77e; }

#create-profile_edit ul.checkbox-list {
  margin-top: 15px;
  color: #96a1b1; }
  @media screen and (max-width: 765px) {
    #create-profile_edit ul.checkbox-list li {
      display: block; } }
  #create-profile_edit ul.checkbox-list li span {
    margin: 0; }
  #create-profile_edit ul.checkbox-list li input {
    margin: 0; }

#create-profile_edit .panel_2 ul {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #96a1b1;
  border-bottom: 1px solid #96a1b1;
  text-align: center; }
  #create-profile_edit .panel_2 ul li {
    display: inline-block;
    margin: 10px 0 0;
    width: 32%; }
    #create-profile_edit .panel_2 ul li:first-child {
      text-align: left; }
      #create-profile_edit .panel_2 ul li:first-child a {
        color: #2b7cc7;
        font-weight: bold; }
    #create-profile_edit .panel_2 ul li:nth-child(2) {
      color: #b0b0b0;
      text-align: center; }
    #create-profile_edit .panel_2 ul li:last-child {
      text-align: right; }
      #create-profile_edit .panel_2 ul li:last-child a {
        color: #2b7cc7; }

#create-profile_edit .panel_3 {
  border-bottom: 0; }
  #create-profile_edit .panel_3 ul li.no-border {
    border: none; }
    #create-profile_edit .panel_3 ul li.no-border.km-slider {
      color: #2b7cc7;
      font-weight: bold; }
  #create-profile_edit .panel_3 ul li {
    position: relative;
    right: 15px;
    margin: 0;
    padding: 10px 15px 10px;
    width: 105%;
    border-bottom: 1px solid #cacaca; }
    #create-profile_edit .panel_3 ul li input {
      margin-right: 5px; }
    #create-profile_edit .panel_3 ul li span.pull-right {
      margin-top: 5px; }
    #create-profile_edit .panel_3 ul li img {
      margin-right: 5px;
      width: 20px; }
    #create-profile_edit .panel_3 ul li:hover {
      background: white; }
    #create-profile_edit .panel_3 ul li a {
      margin-left: 18px; }
    #create-profile_edit .panel_3 ul li a span {
      position: relative;
      top: 3px;
      color: #2b7cc7;
      text-decoration: underline; }
  #create-profile_edit .panel_3 a.button_more-filter {
    padding: 5px;
    font-size: 1.2em;
    font-weight: bold;
    border: 1px solid #cacaca; }
  #create-profile_edit .panel_3 #km-range {
    color: #2b7cc7; }
  #create-profile_edit .panel_3 .menu-files ul li a {
    margin-left: 4px;
    color: #2b7cc7; }
  #create-profile_edit .panel_3 .menu-files ul li span {
    color: #2b7cc7; }
  #create-profile_edit .panel_3 .menu-files ul li:hover a,
  #create-profile_edit .panel_3 .menu-files ul li:hover span {
    color: #96a1b1; }

/*****************************************
* ARTICLE
******************************************/
/* Search details
******************************************/
#article_search-details .container-fluid {
  background: white; }

#article_search-details button.banner {
  display: block;
  width: 100%;
  border-radius: 5px;
  font-size: 1.5em;
  text-align: left; }
  #article_search-details button.banner .glyphicon {
    margin: 0 5px;
    transition: color .4s; }
    #article_search-details button.banner .glyphicon:hover {
      color: #cdcdcd; }
    #article_search-details button.banner .glyphicon.pull-right {
      z-index: 100; }

#article_search-details h4 {
  margin-top: 35px; }

#article_search-details .last-minute {
  background: #e6702e;
  color: white;
  padding: 8px;
  border-radius: 5px; }

#article_search-details ul.form-group {
  margin-top: 25px; }

#article_search-details ul.form-group li {
  position: relative;
  display: inline-block;
  margin: 3px;
  width: 18%;
  vertical-align: middle; }
  #article_search-details ul.form-group li:last-child button {
    margin-left: 10px;
    border-radius: 5px;
    background: black;
    color: white;
    text-align: center;
    width: 80%; }
  #article_search-details ul.form-group li .glyphicon {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #96a1b1; }
    #article_search-details ul.form-group li .glyphicon.caret {
      top: 15px;
      right: 10px;
      left: auto; }
  #article_search-details ul.form-group li select {
    padding: 0 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

#article_search-details ul.checkbox-list li {
  display: inline-block;
  margin: 0;
  padding: 5px; }
  #article_search-details ul.checkbox-list li:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.5); }
  #article_search-details ul.checkbox-list li input {
    margin: 0; }
  #article_search-details ul.checkbox-list li span {
    margin-right: 20px; }

#article_search-details .prinicpal_search_infos {
  border: 1px solid #cacaca;
  background: white; }
  #article_search-details .prinicpal_search_infos span {
    margin: 10px 0; }
  #article_search-details .prinicpal_search_infos a.pull-right {
    margin-top: 10px;
    padding: 5px 15px;
    border: 1px solid #cacaca;
    background: transparent;
    color: black; }
    #article_search-details .prinicpal_search_infos a.pull-right:hover {
      background: #f1f1f1; }
    #article_search-details .prinicpal_search_infos a.pull-right span {
      margin: 0 0 0 3px; }
  #article_search-details .prinicpal_search_infos .glyphicon-info-sign {
    color: #359d59;
    margin-left: 3px;
    font-size: 1em; }

/* Article content
******************************************/
#article_content h3 {
  margin-bottom: 10px; }

#article_content h3 span {
  margin-left: 10px;
  padding: 2px 10px;
  background: #666;
  color: white;
  font-size: 0.6em;
  white-space: nowrap; }

#article_content .panel-heading {
  background: #8c8c8c;
  color: white; }
  #article_content .panel-heading h4 {
    font-weight: 400; }

#article_content ul.article_infos li {
  display: inline-block;
  margin: 0;
  color: #9c9c9c; }
  #article_content ul.article_infos li::after {
    content: ' | '; }
  #article_content ul.article_infos li:last-child::after {
    content: none; }

#article_content .article_description h4 {
  margin-top: 30px;
  color: #2b7cc7; }

#article_content .article_description ul li {
  margin: 0; }

#article_content .panel {
  position: relative;
  margin: 0; }
  #article_content .panel:last-child {
    margin-bottom: 25px; }
  #article_content .panel textarea {
    margin-bottom: 10px;
    padding: 5px;
    max-width: 100%;
    width: 100%;
    border: 1px solid #cacaca;
    resize: vertical; }
  #article_content .panel button[role='button'] {
    display: block;
    float: right;
    width: 150px;
    text-align: center;
    border-radius: 5px; }

#article_content .col-sm-4 button[role='button'] {
  display: block;
  margin: 0 auto;
  margin: 5px 0;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5em; }

#article_content .col-sm-4 ul li {
  color: #959595; }
  #article_content .col-sm-4 ul li b {
    color: black;
    font-weight: 500; }

#article_content .col-sm-4 .panel-body h4 {
  padding-bottom: 15px; }

#article_content .col-sm-4 .panel-body h5 {
  padding: 5px 0; }

#article_content .col-sm-4 .panel-body .glyphicon {
  float: left;
  margin-right: 10px;
  color: #959595;
  font-size: 1.5em; }

#article_content .col-sm-4 .panel-body p {
  padding-top: 5px;
  padding-left: 32px;
  color: #959595;
  font-weight: 400; }

#article_content .col-sm-4 .panel-body iframe {
  width: 100%; }

/*****************************************
* ACCESS
******************************************/
/* Accès recruteur
******************************************/
nav .navbar-access {
  margin-top: 15px; }
  nav .navbar-access ul li {
    display: inline-block;
    padding: 0 5px;
    vertical-align: middle; }
    nav .navbar-access ul li:nth-child(1)::after, nav .navbar-access ul li:nth-child(2)::after {
      padding-left: 10px;
      content: ' | '; }
    nav .navbar-access ul li .glyphicon-chevron-down {
      color: #b8b8b8; }
    nav .navbar-access ul li:last-child a {
      padding: 8px 10px;
      background: #fe7200;
      color: white; }
      nav .navbar-access ul li:last-child a:hover {
        background: #ffb87f; }
    nav .navbar-access ul li:last-child {
      margin-left: 15px; }

#access-recruteur .container-fluid:first-child {
  padding: 0;
  background: white; }

#access-recruteur ul.nav {
  margin-top: 25px; }
  #access-recruteur ul.nav li {
    display: inline-block;
    margin: 0 4px;
    border: 1px solid #cacaca;
    border-bottom: 0; }
    #access-recruteur ul.nav li.active a {
      background: #359d59;
      color: white; }
    #access-recruteur ul.nav li:hover {
      background: #359d59; }
    #access-recruteur ul.nav li a {
      padding: 5px 10px;
      background: #eee; }
      #access-recruteur ul.nav li a:hover {
        border: 0;
        background: 0;
        color: white; }
      #access-recruteur ul.nav li a .glyphicon {
        margin-right: 5px; }
        @media (max-width: 765px) {
          #access-recruteur ul.nav li a .glyphicon {
            margin: 2px;
            font-size: 1em; } }

#access-recruteur .container-fluid:last-child {
  border-top: 1px solid #359d59; }

#access-recruteur .well {
  position: relative;
  margin: 15px 0;
  padding: 15px;
  min-height: 400px; }
  #access-recruteur .well > img {
    display: block;
    margin: 10px auto;
    height: 40px; }
  #access-recruteur .well h4 {
    text-align: center;
    font-weight: 400; }
  #access-recruteur .well hr.blue {
    margin: 15px;
    border-bottom: 1px solid #2b7cc7; }
  #access-recruteur .well ul {
    padding-left: 30px; }
  #access-recruteur .well ul li {
    margin: 10px 0; }
    #access-recruteur .well ul li div.espace {
      padding-left: 32px; }
  #access-recruteur .well .red {
    color: #e83232; }
  #access-recruteur .well ul li.check-list {
    font-size: 1em; }
    #access-recruteur .well ul li.check-list::before {
      position: absolute;
      left: 15px;
      color: #2b7cc7;
      content: '✓';
      font-size: 1.2em; }
  #access-recruteur .well span.number {
    position: absolute;
    left: 10px;
    padding: 3px 8px;
    border: 1px solid #cacaca;
    border-radius: 50%;
    font-size: 0.9em; }
  #access-recruteur .well a.pull-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #2b7cc7;
    text-decoration: underline; }

#access-recruteur .well.grey {
  min-height: auto;
  background: #ddd; }
  #access-recruteur .well.grey h5 {
    margin: 0;
    color: #2b7cc7; }
  #access-recruteur .well.grey h5.espace {
    margin-top: 30px; }
  #access-recruteur .well.grey hr {
    margin: 8px 0; }
  #access-recruteur .well.grey h5.media-heading {
    color: black;
    font-size: 1.15em; }
  #access-recruteur .well.grey .media img {
    width: 75px; }
  #access-recruteur .well.grey .media small {
    color: #999;
    font-size: 1em; }
  #access-recruteur .well.grey .media div {
    font-size: 0.8em; }
  #access-recruteur .well.grey .media a {
    color: #2b7cc7;
    text-decoration: underline; }

#access-recruteur h2 {
  color: #2b7cc7; }

#access-recruteur .article-infos {
  width: 100%; }
  #access-recruteur .article-infos span.pull-right {
    position: absolute;
    right: 10px;
    padding: 5px 10px;
    background: #359d59;
    color: #f5f5f5; }

#access-recruteur .article-answers {
  text-align: center; }
  #access-recruteur .article-answers span {
    margin: 0 5px;
    color: red;
    vertical-align: middle;
    font-weight: bold;
    font-size: 20px; }
  #access-recruteur .article-answers span.glyphicon {
    color: #96a1b1;
    font-size: 25px; }

#access-recruteur #principal_output,
#access-recruteur #principal_output_mobile {
  margin: 0; }
  #access-recruteur #principal_output .container-fluid,
  #access-recruteur #principal_output_mobile .container-fluid {
    background: #ededed; }
  #access-recruteur #principal_output .article-board,
  #access-recruteur #principal_output_mobile .article-board {
    float: left;
    padding: 0 15px;
    width: 100%;
    border: 1px solid #cacaca;
    background: white; }
    @media screen and (max-width: 765px) {
      #access-recruteur #principal_output .article-board,
      #access-recruteur #principal_output_mobile .article-board {
        float: none;
        border: none; }
        #access-recruteur #principal_output .article-board ul li,
        #access-recruteur #principal_output_mobile .article-board ul li {
          display: block !important;
          padding: 0;
          padding-top: 5px;
          min-height: 1px;
          border: none;
          border-top: 1px solid #cacaca; } }
    #access-recruteur #principal_output .article-board ul,
    #access-recruteur #principal_output_mobile .article-board ul {
      margin-top: 10px;
      margin-bottom: 15px;
      text-align: center; }
      @media screen and (max-width: 765px) {
        #access-recruteur #principal_output .article-board ul,
        #access-recruteur #principal_output_mobile .article-board ul {
          margin-bottom: 35px; } }
    #access-recruteur #principal_output .article-board ul li,
    #access-recruteur #principal_output_mobile .article-board ul li {
      display: inline-block;
      margin: 5px 10px;
      font-weight: 500; }
      #access-recruteur #principal_output .article-board ul li span,
      #access-recruteur #principal_output_mobile .article-board ul li span {
        color: #2b7cc7;
        font-weight: normal; }
      #access-recruteur #principal_output .article-board ul li a.red,
      #access-recruteur #principal_output_mobile .article-board ul li a.red {
        color: #a63730; }
    #access-recruteur #principal_output .article-board .active-green,
    #access-recruteur #principal_output_mobile .article-board .active-green {
      padding: 5px 10px;
      background: #359d59;
      color: white; }
    #access-recruteur #principal_output .article-board a.green-link,
    #access-recruteur #principal_output_mobile .article-board a.green-link {
      color: #359d59;
      text-decoration: underline; }

#access-recruteur ul.board .article {
  margin-bottom: 100px; }

#access-recruteur .menu-filter {
  margin-top: 25px; }

#access-recruteur .km-slider {
  color: #2b7cc7; }

#access-recruteur .glyphicon-star.active {
  background: #2b7cc7;
  color: #f5f5f5;
  border: 1px solid #2b7cc7; }

/* Accès candidat
******************************************/
#access-candidat .inside-select {
  position: absolute;
  top: 12px;
  left: 23px;
  color: #96a1b1;
  pointer-events: none;
  z-index: 997; }

#access-candidat .container-fluid:first-child {
  background: white; }

#access-candidat ul.nav {
  margin-top: 15px; }
  #access-candidat ul.nav li {
    display: inline-block;
    margin: 0 4px;
    border: 1px solid #cacaca;
    border-bottom: 0; }
    #access-candidat ul.nav li.active a {
      background: #359d59;
      color: white; }
    #access-candidat ul.nav li:hover {
      background: #359d59; }
    #access-candidat ul.nav li a {
      padding: 5px 10px;
      background: #eee; }
      #access-candidat ul.nav li a:hover {
        border: 0;
        background: 0;
        color: white; }
      #access-candidat ul.nav li a .glyphicon {
        margin-right: 5px; }
        @media (max-width: 765px) {
          #access-candidat ul.nav li a .glyphicon {
            margin: 5px;
            font-size: 1.3em; } }
  #access-candidat ul.nav.job-list {
    margin-top: 15px;
    margin-bottom: 15px; }
    #access-candidat ul.nav.job-list li {
      border: 0; }
      #access-candidat ul.nav.job-list li:hover {
        background: #2b7cc7; }
      #access-candidat ul.nav.job-list li.active a {
        background: #2b7cc7;
        color: white; }
      #access-candidat ul.nav.job-list li a {
        padding: 5px 25px;
        border: 0;
        background: none;
        color: #2b7cc7; }
        #access-candidat ul.nav.job-list li a:hover {
          color: white; }

#access-candidat ul.checkbox-list {
  overflow: hidden;
  margin-top: 20px; }

#access-candidat ul.checkbox-list li {
  position: relative;
  margin: 0;
  padding: 10px 15px 10px;
  width: 100%;
  border-bottom: 1px solid #cacaca;
  background: white; }
  #access-candidat ul.checkbox-list li:first-child {
    border-top: 1px solid #cacaca; }
  #access-candidat ul.checkbox-list li .var {
    margin-left: 23px; }
  #access-candidat ul.checkbox-list li:hover {
    background: white; }
  #access-candidat ul.checkbox-list li span {
    position: relative;
    top: 2px; }
  #access-candidat ul.checkbox-list li input {
    position: relative;
    top: 2px;
    margin-right: 5px; }
  #access-candidat ul.checkbox-list li img {
    margin-right: 4px;
    max-width: 20px; }

#access-candidat ul.checkbox-list li[data-toggle='modal'] span {
  color: #2b7cc7; }

#access-candidat .container-fluid:last-child {
  border-top: 1px solid #359d59; }

#access-candidat .col-md-9 ul.list-top li.child {
  position: relative;
  display: inline-block;
  margin: 3px;
  width: 32%; }
  @media screen and (max-width: 765px) {
    #access-candidat .col-md-9 ul.list-top li.child {
      display: block;
      width: 100%; } }
  #access-candidat .col-md-9 ul.list-top li.child .glyphicon-img {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #96a1b1;
    pointer-events: none; }
    #access-candidat .col-md-9 ul.list-top li.child .glyphicon-img.caret {
      top: 15px;
      right: 10px;
      left: auto; }
  #access-candidat .col-md-9 ul.list-top li.child select:not(.no-form) {
    padding: 0 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

#access-candidat .col-md-9 label {
  margin-right: 25px; }
  @media screen and (max-width: 765px) {
    #access-candidat .col-md-9 label {
      display: block; } }

#access-candidat .col-md-9 button.black {
  margin-left: 10px;
  width: 100%;
  border-radius: 5px;
  background: black !important;
  color: white;
  text-align: center; }

#access-candidat .col-md-9 h3 {
  font-size: 1.6em; }
  #access-candidat .col-md-9 h3 > span {
    margin-top: 10px;
    font-weight: normal;
    font-size: 0.6em; }
    #access-candidat .col-md-9 h3 > span .glyphicon {
      color: #c25903; }

#access-candidat .col-md-9 input[type='text'] {
  width: 100%;
  height: 40px; }

#access-candidat .col-md-9 label.bold {
  width: 100%;
  text-align: left;
  font-weight: bold; }
  #access-candidat .col-md-9 label.bold span {
    vertical-align: bottom; }

#access-candidat .col-md-9 input[type='checkbox'] {
  margin-top: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px; }

#access-candidat .col-md-9 textarea {
  margin-bottom: 20px !important;
  width: 100%;
  height: 100px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  resize: vertical; }

#access-candidat .col-md-9 .panel {
  margin-top: 30px; }

#access-candidat .col-md-9 thead {
  background: black;
  color: white; }

#access-candidat .col-md-9 tbody {
  text-align: center; }

#access-candidat .col-md-9 .alert {
  padding: 0; }
  #access-candidat .col-md-9 .alert ul li {
    display: inline-block;
    width: 30%; }
    @media (max-width: 765px) {
      #access-candidat .col-md-9 .alert ul li {
        width: 100%; } }
  #access-candidat .col-md-9 .alert .panel-body {
    padding: 0 15px; }
  #access-candidat .col-md-9 .alert .panel-body > h3 {
    margin: 0; }

#access-candidat .col-md-9 .alert-list .panel {
  margin: 0; }

#access-candidat .col-md-9 .panel-heading {
  color: #2b7cc7; }

#access-candidat .col-md-9 .button-green {
  position: relative;
  bottom: 15px;
  margin: 10px 0;
  padding: 10px 35px;
  border-radius: 5px;
  background: #359d59; }
  #access-candidat .col-md-9 .button-green:hover {
    background: #7ed39c; }

#access-candidat .col-md-9 .button-top {
  position: relative;
  bottom: 15px;
  margin: 10px 0;
  padding: 10px 35px;
  border-radius: 5px;
  background: #fff; }
  #access-candidat .col-md-9 .button-top:hover {
    background: #7ed39c; }

#access-candidat .col-md-9 button.pourcent {
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  background: #359d59;
  color: white; }

#access-candidat .col-md-9 .green-link {
  padding: 10px;
  color: #359d59;
  text-decoration: underline; }

#access-candidat .col-md-3 .well {
  margin-top: 25px; }
  #access-candidat .col-md-3 .well .well {
    margin: 0;
    padding: 10px; }

#access-candidat .col-md-3 button.first-button {
  display: block;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 5px; }
  #access-candidat .col-md-3 button.first-button div {
    float: left; }
  #access-candidat .col-md-3 button.first-button div.recruteur {
    width: 70%;
    text-align: left;
    text-transform: uppercase; }
  #access-candidat .col-md-3 button.first-button .glyphicon {
    position: relative;
    top: 4px;
    left: 5px;
    padding: 5px;
    border: 3px solid white;
    border-radius: 50%; }

#access-candidat .col-md-3 .profile-pic {
  text-align: center; }
  #access-candidat .col-md-3 .profile-pic img {
    max-width: 350px;
    width: 100%;
    border-radius: 5px; }
  #access-candidat .col-md-3 .profile-pic a {
    position: relative;
    bottom: 30px;
    padding: 2px 10px 2px 5px;
    background: black;
    color: white; }

#access-candidat .col-md-3 h5 {
  margin-top: 25px; }
  #access-candidat .col-md-3 h5.km-slider {
    color: #2b7cc7; }

#access-candidat .col-md-3 a.edit-profile {
  position: relative;
  top: 10px;
  display: block;
  margin: 0 auto;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background: white;
  color: black;
  text-align: center;
  font-weight: 800; }
  #access-candidat .col-md-3 a.edit-profile:hover {
    background: #f1f1f1; }

#access-candidat .col-md-3 ul.media img.media-object {
  width: 50px; }

#access-candidat .col-md-3 ul.media li {
  margin-top: 0;
  padding-top: 15px;
  border-top: 1px solid #cacaca; }

#access-candidat .col-md-3 .menu-filter a {
  margin-left: 0;
  border: 1px solid #cacaca;
  background: white;
  color: #96a1b1; }

#access-candidat .experience {
  padding-bottom: 15px; }

#access-candidat a.green-link {
  padding-top: 5px;
  color: #359d59;
  text-decoration: underline;
  font-size: 15px; }
  #access-candidat a.green-link .glyphicon {
    margin-left: 5px; }

/*****************************************
* MOBILE
******************************************/
/* Mobile Header General
******************************************/
.mobile-header .container-fluid {
  padding-top: 40px;
  background: #f5f5f5; }

.mobile-header .homepage_cover_first-buttons {
  margin-bottom: 30px; }
  .mobile-header .homepage_cover_first-buttons a {
    clear: left;
    margin: 25px 0;
    padding: 15px 20px; }
    .mobile-header .homepage_cover_first-buttons a:nth-child(2) {
      width: 50px;
      background: #f17917; }

.mobile-header ul label {
  padding-bottom: 5px;
  font-weight: 400;
  font-size: 1.5em; }

.mobile-header ul select.form-control {
  height: 50px;
  background: #f5f5f5; }

.mobile-header .homepage_cover_last-buttons {
  margin-top: 40px; }
  .mobile-header .homepage_cover_last-buttons a:first-child {
    border: 1px solid #cacaca;
    background: #f5f5f5; }
  .mobile-header .homepage_cover_last-buttons a:last-child {
    background: #2b7cc7;
    color: white;
    font-weight: 400; }

/* Mobile Homepage
******************************************/
#homepage_faq_mobile .container-fluid {
  background: #f5f5f5; }

#homepage_faq_mobile h3 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 300; }
  #homepage_faq_mobile h3 span.fa {
    padding-right: 10px;
    color: black; }

#homepage_faq_mobile p {
  margin-bottom: 60px;
  padding: 20px;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background: white; }

/* Prinicpal Output Mobile
******************************************/
#principal_output_mobile {
  margin-bottom: 15px; }
  #principal_output_mobile span.pull-left {
    color: #a2a2a2;
    font-size: 0.9em; }
  #principal_output_mobile a.pull-right {
    border: 1px solid #cacaca;
    background: white;
    color: #919191;
    font-size: 0.9em; }
    #principal_output_mobile a.pull-right:hover {
      background: #f1f1f1; }
  #principal_output_mobile .principal_output {
    margin-top: 25px; }
    #principal_output_mobile .principal_output ul li {
      position: relative;
      padding: 15px;
      min-height: 200px;
      border: 1px solid #cacaca;
      background: white;
      margin-bottom: 20px; }
    #principal_output_mobile .principal_output [class^='article-part'] {
      margin: 5px 0; }
    #principal_output_mobile .principal_output .article-part1 > span {
      position: absolute;
      top: -5px;
      right: 0;
      left: 0;
      margin: auto;
      padding: 2px 10px;
      width: 180px;
      background: #9a9797;
      color: white;
      text-align: center; }
    #principal_output_mobile .principal_output .article-part1 .article-origin {
      color: #9d9b9b; }
    #principal_output_mobile .principal_output .article-part1 .article-content {
      font-size: 1.4em; }
    #principal_output_mobile .principal_output .article-part1 .article-infos {
      position: absolute;
      bottom: 0;
      margin-bottom: 10px; }
      #principal_output_mobile .principal_output .article-part1 .article-infos span {
        color: #bebebe; }
      #principal_output_mobile .principal_output .article-part1 .article-infos > span:first-child::after,
      #principal_output_mobile .principal_output .article-part1 .article-infos > span:nth-child(2)::after {
        content: ' | '; }
      #principal_output_mobile .principal_output .article-part1 .article-infos > span:last-child {
        color: #2b7cc7; }
    #principal_output_mobile .principal_output .article-part2 > div {
      color: #bebebe;
      font-weight: 500; }
    #principal_output_mobile .principal_output .article-part2 span {
      padding: 2px 4px;
      background: #96a1b1;
      color: white;
      line-height: 2em; }
      #principal_output_mobile .principal_output .article-part2 span.lang {
        background: #d42322; }
    #principal_output_mobile .principal_output .article-part3 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 25px; }
      #principal_output_mobile .principal_output .article-part3:hover {
        color: none; }
  #principal_output_mobile a[role='button'] {
    position: relative;
    bottom: 0;
    margin-top: 5px;
    border: 1px solid #cacaca;
    background: white;
    color: #bfbfbf;
    text-align: center;
    padding: 5px 10px; }
    #principal_output_mobile a[role='button']:hover {
      background: #f2f2f2; }
