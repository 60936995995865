/*****************************************
* CREATE PROFILE PAGE
******************************************/
/* Steps
******************************************/
#create-profile_steps {
  margin-bottom: 25px;
  border: $border_little-grey;
  .container-fluid {
    background: white;
  }
  h2 {
    margin-bottom: 40px;
    text-align: center;
    font-weight: 200;
  }
  hr {
    position: relative;
    width: 72%;
    border-bottom: 2px solid #d6d6d6;
  }
  ul li {
    position: relative;
    top: -35px;
    left: 15px;
    display: inline-block;
    float: left;
    margin: auto;
    padding: 0;
    width: 24%;
    text-align: center;
    div:first-child {
      margin: auto;
      padding-top: 3px;
      width: 30px;
      height: 30px;
      border: 3px solid #d6d6d6;
      border-radius: 50%;
      background: white;
    }
    div:last-child {
      padding-top: 5px;
      color: #a8a8a8;
    }
    &.active div:first-child {
      background: $color_contrast_blue;
      color: white;
    }
  }
}
/* Edit part
******************************************/
#create-profile_edit {
  .notification {
    padding-right: 50px;
    padding-left: 50px;
    @media screen and (max-width: $mobile) {
      text-align: center;
    }
    [class^='col-sm-'] {
      padding: 15px 0;
    }
    .col-sm-7 {
      padding: 15px;
      padding-left: 50px;
      @media (max-width: $tablet) {
        padding-left: 0;
      }
    }
    .fa-envelope {
      padding: 0 25px;
      float:right;
      color: #737373;
      font-size: 4em;
      @media (max-width: $mobile) {
        float:none;
      }
    }
    input {
      margin-top: 5px;
      width: 75px;
      border: $border_little-grey;
    }
    button[role='button'] {
      margin-top: 5px;
      padding: 5px 10px;
      padding-right: 12px;
      background: $color_contrast_green;
      font-size: .8em;
      &:hover {
        background: lighten($color_contrast_green, 25%);
      }
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
  .page {
    margin-top: 50px;
    h2 {
      margin-bottom: 50px;
      font-weight: 400;
    }
    & > span {
      position: relative;
      bottom: 5px;
      padding: 8px 25px;
      border: $border_little-grey;
      border-bottom: 1px solid white;
      border-radius: 5px 5px 0 0;
      background: white;
      color: $color_contrast_blue;
    }
    .well {
      position: relative;
      .glyphicon-chevron-right,
      .glyphicon-chevron-left {
        position: absolute;
        top: 45%;
        padding: 10px;
        border: 4px solid $color_contrast_blue;
        border-radius: 50%;
        color: $color_contrast_blue;
        font-size: 2em;
        transition: 0.5s;
        &:hover {
          border: 4px solid lighten($color_contrast_blue, 25%);
          color: lighten($color_contrast_blue, 25%);
          transition: 0.5s;
        }
        &.disabled {
          border: 4px solid #d1d1d1;
          color: #d1d1d1;
          cursor: default;
        }
        &.glyphicon-chevron-right {
          right: -70px;
        }
        &.glyphicon-chevron-left {
          left: -70px;
        }
      }
      .col-md-6 {
        padding: 30px;
        min-height: 650px;
        &:first-child {
          border-right: $border_little-grey;
          @media screen and (max-width: $tablet) {
            border-right: 0;
          }
        }
      }
      .media {
        img {
          float: left;
          width: 100px;
          height: 100px;
          border: $border_little-grey;
          border-radius: 50%;
        }
        h3 {
          padding-left: 15px;
          font-size: 1.3em;
        }
        label {
          margin-left: 15px;
        }
      }
      .civil {
        margin: 25px 0;
        span {
          margin-right: 15px;
          font-weight: bold;
        }
        input {
          margin-right: 3px;
        }
        label {
          margin-right: 15px;
          font-weight: 400;
        }
      }
      .form-group {
        margin-top: 25px;
      }
      .form-group.address {
        margin-top: 25px;
        .form-control {
          margin-bottom: 15px;
        }
      }
      .form-group input[placeholder='Code Postal'],
      .form-group input[placeholder='Localité'] {
        float: left;
        width: 48%;
      }
      .form-group input[placeholder='Code Postal'] {
        margin-right: 15px;
      }
      select.form-control {
        color: #9f9f9f;
      }
      .form-group.question {
        input {
          /*margin-top: 10px;
          margin-right: 3px;*/
        }
        label {
          margin-right: 15px;
        }
        select {
          float: right;
          width: 33%;
        }
        input[type="date"] {
          width: 100px;
        }
      }
      .button-green {
        position: absolute;
        right: 30px;
        bottom: 15px;
        width: 33%;
        border-radius: 3px;
        background: $color_contrast_green;
        text-align: center;
      }
    }
  }
}
