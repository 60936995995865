/*****************************************
* MOBILE
******************************************/
/* Mobile Header General
******************************************/
.mobile-header {
  .container-fluid {
    padding-top: 40px;
    background: $color_bg_main;
  }
  .homepage_cover_first-buttons {
    margin-bottom: 30px;
    a {
      clear: left;
      margin: 25px 0;
      padding: 15px 20px;
      &:nth-child(2) {
        width: 50px;
        background: #f17917;
      }
    }
  }
  ul {
    label {
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 1.5em;
    }
    select.form-control {
      height: 50px;
      background: $color_bg_main;
    }
  }
  .homepage_cover_last-buttons {
    margin-top: 40px;
    a:first-child {
      border: $border_little-grey;
      background: $color_bg_main;
    }
    a:last-child {
      background: $color_contrast_blue;
      color: white;
      font-weight: 400;
    }
  }
}
/* Mobile Homepage
******************************************/
#homepage_faq_mobile {
  .container-fluid {
    background: $color_bg_main;
  }
  h3 {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 300;
    span.fa {
      padding-right: 10px;
      color: black;
    }
  }
  p {
    margin-bottom: 60px;
    padding: 20px;
    border: $border_little-grey;
    border-radius: 2px;
    background: white;
  }
}
/* Prinicpal Output Mobile
******************************************/
#principal_output_mobile {
  margin-bottom: 15px;
  span.pull-left {
    color: #a2a2a2;
    font-size: 0.9em;
  }
  a.pull-right {
    border: $border_little-grey;
    background: white;
    color: #919191;
    font-size: 0.9em;
    &:hover {
      background: #f1f1f1;
    }
  }
  .principal_output {
    margin-top: 25px;
    ul li {
      position: relative;
      padding: 15px;
      min-height: 200px;
      border: $border_little-grey;
      background: white;
      margin-bottom: 20px;
      
    }
    [class^='article-part'] {
      margin: 5px 0;
    }
    .article-part1 {
      & > span {
        position: absolute;
        top: -5px;
        right: 0;
        left: 0;
        margin: auto;
        padding: 2px 10px;
        width: 180px;
        background: #9a9797;
        color: white;
        text-align: center;
      }
      .article-origin {
        color: #9d9b9b;
      }
      .article-content {
        font-size: 1.4em;
      }
      .article-infos {
        position: absolute;
        bottom: 0;
        margin-bottom: 10px;
        span {
          color: #bebebe;
        }
        & > span:first-child::after,
        & > span:nth-child(2)::after {
          content: ' | ';
        }
        & > span:last-child {
          color: $color_contrast_blue;
        }
      }
    }
    .article-part2 {
      & > div {
        color: #bebebe;
        font-weight: 500;
      }
      span {
        padding: 2px 4px;
        background: $color_grey_blue;
        color: white;
        line-height: 2em;
        &.lang {
          background: #d42322;
        }
      }
    }
    .article-part3 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 25px;
      &:hover {
        color: none;
      }
    }
  }
  a[role='button'] {
    position: relative;
    bottom: 0;
    margin-top: 5px;
    border: $border_little-grey;
    background: white;
    color: #bfbfbf;
    text-align: center;
    padding: 5px 10px;
    &:hover {
      background: #f2f2f2;
    }
  }
}
