#new-account {
  h3 {
    position: relative;
    left: 20px;
    margin-bottom: 25px;
  }
  ul.nav-tabs {
    position: relative;
    top: 2px;
    left: 15px;
    z-index: 10;
    border: 0;
    li {
      margin: 0 5px;
      padding: 0;
      border: $border_little-grey;
      border-radius: 5px 5px 0 0;
      background: white;
      &.active {
        border-bottom: 2px solid white;
        a {
          color: $color_contrast_blue;
          background: white;
        }
      }
      &:first-child {
        margin-left: 0;
      }
      a {
        padding: 5px 20px;
        border: 0;
        background: $color_contrast_blue;
        color: $color_bg_main;
        margin: 0;
      }
    }
  }
  .col-sm-6 {
    position: relative;
    margin-bottom: 30px;
    .civil {
      margin-bottom: 15px;
      span {
        margin-right: 15px;
        font-weight: bold;
      }
      input {
        margin: 0 3px;
      }
      label {
        margin-right: 10px;
      }
    }
    .form-group.half-input {
      min-height: 85px;
      .form-control {
        float: left;
        width: 45%;
        &:last-child {
          float: right;
        }
      }
    }
    .doc {
      margin-bottom: 15px;
      a {
        color: $color_contrast_blue;
      }
    }
    .glyphicon.glyphicon-ok {
      color: $color_contrast_green;
    }
    button[role='button'] {
      position: absolute;
      right: 0;
      bottom: 4px;
      left: 0;
      display: block;
      margin: auto;
      width: 50%;
      max-width: 200px;
      border-radius: 5px;
      background: $color_contrast_green;
      text-align: center;
      font-weight: bold;
      @media screen and (max-width: $mobile) {
        bottom: -20px;
      }
      &:hover {
        background: lighten($color_contrast_green,25%);
      }
    }
    &.part1 {
      padding-right: 0;
    }
    &.part2 {
      padding-left: 0;
      .well {
        position: relative;
        padding: 40px;
        border-left: 0;
      }
      a,
      span {
        font-size: 1.2em;
      }
      button[role='button'] {
        bottom: -18px;
        font-size: 1em;
      }
      .form-control {
        background: none;
      }
    }
  }
}
