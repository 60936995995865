/*****************************************
* PRINCIPAL
******************************************/
/* Search bar
******************************************/

.white-search #principal_search .tab-content, .white-search #principal_search_lastminute{
  color: rgb(51, 51, 51) !important;
  background: white !important;
}

#principal_search {
  border-bottom: $border_little-grey;
  .glyphicon-info-sign {
    color: $color_contrast_green;
    margin-left: 3px;
    font-size: 1em;
  }
  .container-fluid {
    padding-top: 20px;
    background: white;
  }
  .container {
    position: relative;
  }
  ul.nav li {
    margin: 0 10px 0 0;
    border-radius: 5px 5px 0 0;
    a {
      margin-bottom: 5px;
      padding: 0;
      border-bottom: 3px solid #e37f09;
      border-radius: 5px 5px 0 0;
      background: white;
      color: black;
      text-transform: uppercase;
    }
    &:first-child a {
      margin-right: 10px;
      border-bottom: 3px solid $color_contrast_blue;
    }
  }
  span.pull-right.result {
    position: absolute;
    top: 0;
    right: 15px;
    text-align: right;
    b {
      padding: 0 2px;
      font-size: 25px;
    }
  }
  .tab-content #principal_search_lastminute {
    background: #e37f09;
  }
  .tab-pane {
    padding: 10px;
  }
  .tab-content {
    position: relative;
    background: $color_contrast_blue;
    color: white;
    h4 {
      font-weight: 100em;
    }
    ul.form-group {
      margin: 0;
      height: 110px;
      li {
        display: inline-block;
        margin: 3px;
        width: 18%;
        &:last-child button {
          position: relative;
          top: -26px;
          margin-left: 10px;
          border-radius: 5px;
          background: black;
          color: white;
        }
      }
      select {
        padding-right: 25px;
        padding-left: 30px;

        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
      }
      .glyphicon {
        position: relative;
        top: -25px;
        left: 9px;
        color: $color_grey_blue;
        &.caret {
          top: -20px;
          left: -10px;
        }
      }
    }
    ul.checkbox-list {
      position: absolute;
      bottom: 2px;
      margin: 0;
      li {
        padding: 5px;
      }
    }
  }
  .prinicpal_search_infos {
    span {
      margin: 10px 0;
    }
    a.pull-right {
      margin-top: 10px;
      padding: 5px 15px;
      border: $border_little-grey;
      background: transparent;
      color: black;
      &:hover {
        background: #f1f1f1;
      }
      span {
        margin: 0 0 0 3px;
      }
    }
  }
  select {
    color: $color_grey_blue;
  }
}
/* Prinicpal Output
******************************************/
#principal_output {
  margin-top: 25px;
  margin-bottom: 75px;
  .prinicpal_output_filters label{
    font-weight: normal;
  }
  .glyphicon-info-sign {
    color: $color_contrast_green;
    margin-left: 3px;
    font-size: 1em;
  }
  [class^='article-part'] {
    position: relative;
    float: left;
    padding: 12px;
    width: 33.3%;
    height: 100%;
  }
  .article {
    margin-bottom: 30px;
    width: 100%;
    height: 150px;
    border: 1px solid #d7d7d7;
    background: white;
    transition: background 0.5s;
    &:hover {
      background: darken(white, 6%);
    }
  }
  .article-origin {
    margin-bottom: 8px;
    border-bottom: $border_little-grey;
    color: $color_grey_blue;
    font-weight: 500;
  }
  .article-part1 {
    border-right: none;
    & > span {
      position: absolute;
      top: -10px;
      right: 33%;
      padding: 2px 25px;
      background: $color_grey_blue;
      color: white;
    }
    .article-content {
      display: -webkit-box;
      overflow: hidden;
      max-height: 1.3*2;
      text-overflow: ellipsis;
      font-size: 1.2em;
      line-height: 1.3;

      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .article-infos {
      position: absolute;
      bottom: 0;
      margin-bottom: 5px;
      span {
        color: $color_grey_blue;
      }
      & > span:first-child::after,
      & > span:nth-child(2)::after {
        padding: 0 3px;
        content: ' | ';
      }
      & > span:last-child {
        color: $color_contrast_blue;
      }
    }
    .media {
      margin: 0;
      padding-bottom: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      .media-body {
        margin: auto 0;
      }
      ul li {
        margin: 0;
      }
    }
    picture {
      position: relative;
      display: block;
      overflow: hidden;
      width: 80px;
      margin: auto 0;
      margin-right: 15px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #8b8b8b;
      flex-shrink: 0;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        transform: translate(-50%,-50%);
      }
    }
  }
  .article-part2 {
    border-right: none;
    border-left: none;
    & > div {
      color: $color_grey_blue;
      font-weight: 500;
    }
    span {
      padding: 2px 4px;
      background: $color_grey_blue;
      color: white;
      white-space: nowrap;
      line-height: 2em;
      &.lang {
        background: #d42322;
      }
    }
  }
  .article-part3 {
    border-left: none;
    .article-origin {
      text-align: center;
    }
    hr {
      margin: 0 auto;
      margin-bottom: 8px;
      width: 125px;
    }
    .article-label {
      margin: auto;
      padding: 4px 6px;
      width: 100px;
      background: #f56a11;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-weight: 200;
      font-size: 0.8em;
      margin-bottom: 5px;
      &.purple {
        background: #9e2ab6;
      }
      &.blue {
        background: #243682;
      }
      &.red {
        background: #d22;
      }
    }
    .article-pourcent {
      margin: 5px 0;
      padding-left: 5px;
      text-align: center;
      font-weight: 700;
      font-size: 1.8em;
    }
    /*button {
      float: right;
      background: none;
      color: $color_grey_blue;
      z-index: 100;
      width: 35px;
      height: 35px;
      border: 1px solid $color_grey_blue;
      padding: 0;
      border-radius: 50%;
      &:hover {
        color:$color_contrast_blue;
        background: white;
      }
    }*/
  }
  a[role='button'] {
    position: relative;
    margin-top: 5px;
    border: $border_little-grey;
    background: white;
    color: $color_grey_blue;
    text-align: center;
    &:hover {
      background: #f2f2f2;
    }
  }
}
.col-md-3 {
  h4 {
    margin-bottom: 15px;
    margin-left: 15px;
    color: $color_contrast_blue;
    &.km-slider {
      font-size: .95em;
      margin: 0;
      margin-bottom: 20px;
      .glyphicon {
        margin-right: 5px;
      }
    }
  }
  .access {
    margin-left: 15px;
    color: $color_contrast_blue;
  }
  ul {
    margin-bottom: 35px;
  }
  ul.checkbox-list li {
    position: relative;
    margin: 0;
    padding: 10px 15px 10px;
    width: 100%;
    border-bottom: $border_little-grey;
    &:hover {
      background: lighten($color_bg_main, 25%);
    }
    &:last-child a {
      color: $color_contrast_blue;
    }
    span {
      position: relative;
      top: 4px;
    }
    &[data-toggle='modal'] {
      color: $color_contrast_blue;
    }
    input {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
    img {
      margin-right: 4px;
      max-width: 20px;
    }
    .glyphicon {
      color: $color_contrast_blue;
      font-size: 1.4em;
      margin-right: 5px;
    }
    .blue {
      color: $color_contrast_blue;
      vertical-align: top;
    }
  }
  a[role='button'] {
    padding: 15px;
    border: $border_little-grey;
    background: white;
    color: $color_grey_blue;
    &:hover {
      background: #f5f5f5;
    }
  }
  .menu-filter-list ul li {
    &:last-child a {
      color: black;
    }
  }
  .form-control-feedback {
    left: 30px;
    color: $color_grey_blue;
    font-size: 1.5em;
  }
}
