#create-profile_steps {
  button {
    width: 100%;
    border-radius: 5px;
    background: $color_contrast_green;
    text-transform: uppercase;
  }
  a {
    display: block;
    margin-bottom: 15px;
    color: $color_contrast_green;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
  }
  .steps {
    float: left;
    width: 100%;
  }
  .button-float {
    margin-bottom: 10px;
  }
  @media screen and (min-width: $tablet) {
    .container {
      position: relative;
    }
    .button-float {
      position: absolute;
      right: 20px;
      bottom: 40px;
    }
    .steps {
      float: left;
      width: 75%;
    }
  }
}

#create-profile_edit {
  .link-style{
    padding-left: 30px !important;
    border-bottom: 0 !important;
    a{
      text-decoration: underline;
      color: #2b7cc7;
      i{
        margin-right: 5px;
        color: #2b7cc7;
        font-size: 17px;
      }
    }
  }
  .km{
    border: 0 !important;
    h5{
      display: initial;
    }
    h5, i{
      color: #2b7cc7;
      font-weight: bold;
    }
  }
  .page .well ul li button[role='button'] {
    position: static;
    float: right;
    padding: 0;
    width: 125px;
    background: transparent;
    color: $color_contrast_green;
    span.text {
      text-decoration: underline;
      text-transform: uppercase;
    }
    span.glyphicon {
      float: left;
      font-size: 1.3em;
    }
    &:hover {
      color: lighten($color_contrast_green, 20);
    }
  }
  ul {
    margin-bottom: 50px;
  }
  .page .well .panel-heading button[role='button'] {
    position: static;
    display: block;
    margin: 0;
    padding: 10px 17px;
    width: 100%;
    border-radius: 0;
    background: $color_contrast_blue;
    color: $color_bg_main;
    text-align: left;
    &:hover {
      background: lighten($color_contrast_blue, 10%);
    }
  }
  .page .well .panel-heading:nth-child(2) {
    padding: 0;
    .glyphicon {
      margin-right: 10px;
      padding: 0;
    }
  }
  .page .well button[role='button'].next-step {
    position: relative;
    right: 0;
    margin-top: 10px;
    width: 300px;
    text-transform: uppercase;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
    &:hover {
      background: lighten($color_contrast_green, 15%);
    }
  }
  ul.checkbox-list {
    margin-top: 15px;
    color: $color_grey_blue;
    @media screen and (max-width: $mobile) {
      li {
        display: block;
      }
    }
    li span {
      margin: 0;
    }
    li input {
      margin: 0;
    }
  }
  .panel_2 {
    ul {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-top: 1px solid $color_grey_blue;
      border-bottom: 1px solid $color_grey_blue;
      text-align: center;
      li {
        display: inline-block;
        margin: 10px 0 0;
        width: 32%;
        &:first-child {
          text-align: left;
          a {
            color: $color_contrast_blue;
            font-weight: bold;
          }
        }
        &:nth-child(2) {
          color: #b0b0b0;
          text-align: center;
        }
        &:last-child {
          text-align: right;
          a {
            color: $color_contrast_blue;
          }
        }
      }
    }
  }
  .panel_3 {
    border-bottom: 0;
    ul li.no-border {
      border: none;
      &.km-slider {
        color: $color_contrast_blue;
        font-weight: bold;
      }
    }
    ul li {
      position: relative;
      right: 15px;
      margin: 0;
      padding: 10px 15px 10px;
      width: 105%;
      border-bottom: $border_little-grey;
      input {
        margin-right: 5px;
      }
      span.pull-right {
        margin-top: 5px;
      }
      img {
        margin-right: 5px;
        width: 20px;
      }
      &:hover {
        background: lighten($color_bg_main, 25%);
      }
      a {
        margin-left: 18px;
      }
      a span {
        position: relative;
        top: 3px;
        color: $color_contrast_blue;
        text-decoration: underline;
      }
    }
    a.button_more-filter {
      padding: 5px;
      font-size: 1.2em;
      font-weight: bold;
      border: $border_little-grey;
    }
    #km-range {
      color: $color_contrast_blue;
    }
    .menu-files {
      ul li a {
        margin-left: 4px;
        color: $color_contrast_blue;
      }
      ul li span {
        color: $color_contrast_blue;
      }
      ul li:hover a,
      ul li:hover span {
        color: $color_grey_blue;
      }
    }
  }
}
