/*****************************************
* HOMEPAGE
******************************************/
/* Homepage Cover
******************************************/
#homepage_cover {
  .container-fluid {
    min-height: 500px;
    background-image: url('../img/home_background.jpg');
    background-size: cover;
  }
  .container {
    position: relative;
    margin: 100px auto;
    padding: 5px 25px;
    background: transparentize(white, 0.75);
    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      width: 100%;
    }
  }
  .homepage_cover_label {
    position: absolute;
    top: -20px;
    padding: 5px 20px;
    background: #e6702e;
    color: white;
  }
  .homepage_cover_result {
    position: absolute;
    top: -35px;
    right: 0;
    color: white;
    b {
      padding: 0 2px;
      font-size: 25px;
    }
  }
  .homepage_cover_search {
    position: absolute;
    right: 0;
    bottom: -35px;
    left: 0;
    z-index: 1;
    text-align: center;
    button {
      width: 250px;
    }
  }
  .homepage_cover_lastminute li {
    margin: 25px 0 25px-8px 20px;
    width: 215px;
    border-radius: 5px;
    background: white;
    span {
      margin-right: 5px;
    }
    input {
      position: relative;
      right: 25px;
    }
    .glyphicon-info-sign {
      vertical-align: middle;
      margin: 0;
      font-size: 1.2em;
    }
  }
  .col-md-3,
  .col-md-2 {
    padding: 0 5px;
    @media screen and (max-width: $mobile) {
      width: 100%;
    }
    .well {
      overflow: hidden;
      padding: 2px 10px;
      min-height: 310px;
      background: transparentize(white, 0.18);
      color: #757575;
    }
    h4 {
      margin: 15px 0;
      color: #505050;
      font-size: 1.2em;
    }
    hr {
      margin-left: -50px;
      width: 200%;
    }
    .panel_1 ul li {
      select {
        color: $color_grey_blue;
        text-indent: 25px;
      }
      .form-control-feedback {
        left: 0;
        color: $color_grey_blue;
        font-size: 15px;
      }
    }
    .panel_2 ul li,
    .panel_3 ul li {
      margin: 0;
      padding: 8px;
      width: 100%;
      border-bottom: none;
      border-radius: 5px;
      &:last-child {
        a {
          color: $color_contrast_blue;
          &:hover {
            text-decoration: underline;
          }
        }
        .fa {
          padding-right: 10px;
          color: $color_contrast_blue;
          font-size: 1.3em;
        }
      }
      span {
        color: $color_grey_blue;
      }
    }
    .panel_2 hr,
    .panel_3 hr {
      margin-bottom: 15px;
    }
    .panel_3 ul li {
      span {
        margin-left: 6px;
      }
      &:last-child {
        input {
          visibility: hidden;
        }
        span {
          position: relative;
          top: 2px;
          color: $color_contrast_blue;
        }
      }
      .form-control-feedback {
        left: 15px;
        margin-top: 5px;
        font-size: 1.4em;
      }
    }
    img {
      max-width: 25px;
    }
    input[type='checkbox'] {
      margin-right: 7px;
    }
  }
}

/* Homepage FAQ
******************************************/
#homepage_faq {
  .container {
    margin: 50px auto 100px;
  }
  ul.nav-pills li {
    margin: 0;
    padding: 0 12px;
    width: 25%;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &.active a {
      background: white;
    }
    a {
      overflow: hidden;
      border: $border_little-grey;
      border-bottom: 0;
      border-radius: 0;
      background: #e2e2e2;
      color: #838383;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: 0.8s;
      span:last-child {
        width: 125px;
      }
      &:hover {
        background: white;
        transition: 0.2s;
      }
    }
    .fa {
      margin-right: 5px;
      color: #4d4d4d;
      font-size: 1.2em;
    }
  }
  .tab-content {
    margin: 0;
    min-height: 200px;
    border: $border_little-grey;
    background: white;
  }
  .tab-pane {
    padding: 30px 45px;
    h3 {
      margin: 0;
      padding: 12px 0 25px;
    }
  }
}
