/*****************************************
* ACCESS
******************************************/
/* Accès recruteur
******************************************/
nav {
  .navbar-access {
    margin-top: 15px;
    ul li {
      display: inline-block;
      padding: 0 5px;
      vertical-align: middle;
      &:nth-child(1)::after,
      &:nth-child(2)::after {
        padding-left: 10px;
        content: ' | ';
      }
      .glyphicon-chevron-down {
        color: #b8b8b8;
      }
      &:last-child a {
        padding: 8px 10px;
        background: #fe7200;
        color: white;
        &:hover {
          background: lighten(#fe7200, 25%);
        }
      }
      &:last-child {
        margin-left: 15px;
      }
    }
  }
}
#access-recruteur {
  .container-fluid:first-child {
    padding: 0;
    background: white;
  }
  ul.nav {
    margin-top: 25px;
    li {
      display: inline-block;
      margin: 0 4px;
      border: $border_little-grey;
      border-bottom: 0;
      &.active a {
        background: $color_contrast_green;
        color: white;
      }
      &:hover {
        background: $color_contrast_green;
      }
      a {
        padding: 5px 10px;
        background: #eee;
        &:hover {
          border: 0;
          background: 0;
          color: white;
        }
        .glyphicon {
          margin-right: 5px;
          @media (max-width: $mobile) {
            margin: 2px;
            font-size: 1em;
          }
        }
      }
    }
  }
  .container-fluid:last-child {
    border-top: 1px solid $color_contrast_green;
  }
  .well {
    position: relative;
    margin: 15px 0;
    padding: 15px;
    min-height: 400px;
    & > img {
      display: block;
      margin: 10px auto;
      height: 40px;
    }
    h4 {
      text-align: center;
      font-weight: 400;
    }
    hr.blue {
      margin: 15px;
      border-bottom: 1px solid $color_contrast_blue;
    }
    ul {
      padding-left: 30px;
    }
    ul li {
      margin: 10px 0;
      div.espace {
        padding-left: 32px;
      }
    }
    .red {
      color: #e83232;
    }
    ul li.check-list {
      font-size: 1em;
      &::before {
        position: absolute;
        left: 15px;
        color: $color_contrast_blue;
        content: '✓';
        font-size: 1.2em;
      }
    }
    span.number {
      position: absolute;
      left: 10px;
      padding: 3px 8px;
      border: $border_little-grey;
      border-radius: 50%;
      font-size: 0.9em;
    }
    a.pull-right {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $color_contrast_blue;
      text-decoration: underline;
    }
  }
  .well.grey {
    min-height: auto;
    background: #ddd;
    h5 {
      margin: 0;
      color: $color_contrast_blue;
    }
    h5.espace {
      margin-top: 30px;
    }
    hr {
      margin: 8px 0;
    }
    h5.media-heading {
      color: black;
      font-size: 1.15em;
    }
    .media img {
      width: 75px;
    }
    .media small {
      color: #999;
      font-size: 1em;
    }
    .media div {
      font-size: 0.8em;
    }
    .media a {
      color: $color_contrast_blue;
      text-decoration: underline;
    }
  }
  h2 {
    color: $color_contrast_blue;
  }
  .article-infos {
    width: 100%;
    span.pull-right {
      position: absolute;
      right: 10px;
      padding: 5px 10px;
      background: $color_contrast_green;
      color: $color_bg_main;
    }
  }
  .article-answers {
    text-align: center;
    span {
      margin: 0 5px;
      color: red;
      vertical-align: middle;
      font-weight: bold;
      font-size: 20px;
    }
    span.glyphicon {
      color: $color_grey_blue;
      font-size: 25px;
    }
  }
  #principal_output,
  #principal_output_mobile {
    margin: 0;
    .container-fluid {
      background: darken($color_bg_main, 3%);
    }
    .article-board {
      float: left;
      padding: 0 15px;
      width: 100%;
      border: $border_little-grey;
      background: white;
      @media screen and (max-width: $mobile) {
        float: none;
        border: none;
        ul li {
          display: block!important;
          padding: 0;
          padding-top: 5px;
          min-height: 1px;
          border: none;
          border-top: $border_little-grey;
        }
      }
      ul {
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: center;
        @media screen and (max-width: $mobile) {
          margin-bottom: 35px;
        }
      }
      ul li {
        display: inline-block;
        margin: 5px 10px;
        font-weight: 500;
        span {
          color: $color_contrast_blue;
          font-weight: normal;
        }
        a.red {
          color: #a63730;
        }
      }
      .active-green {
        padding: 5px 10px;
        background: $color_contrast_green;
        color: white;
      }
      a.green-link {
        color: $color_contrast_green;
        text-decoration: underline;
      }
    }
  }
  ul.board .article {
    margin-bottom: 100px;
  }
  .menu-filter {
    margin-top: 25px;
  }
  .km-slider {
    color: $color_contrast_blue;
  }
  .glyphicon-star.active {
    background: $color_contrast_blue;
    color: $color_bg_main;
    border: 1px solid $color_contrast_blue;
  }
}
/* Accès candidat
******************************************/
#access-candidat {
  .inside-select{
    position: absolute;
    top: 12px;
    left: 23px;
    color: #96a1b1;
    pointer-events: none;
    z-index: 997;
  }
  .container-fluid:first-child {
    background: white;
  }
  ul.nav {
    margin-top: 15px;
    li {
      display: inline-block;
      margin: 0 4px;
      border: $border_little-grey;
      border-bottom: 0;
      &.active a {
        background: $color_contrast_green;
        color: white;
      }
      &:hover {
        background: $color_contrast_green;
      }
      a {
        padding: 5px 10px;
        background: #eee;
        &:hover {
          border: 0;
          background: 0;
          color: white;
        }
        .glyphicon {
          margin-right: 5px;
          @media (max-width: $mobile) {
            margin: 5px;
            font-size: 1.3em;
          }
        }
      }
    }
    &.job-list {
      margin-top: 15px;
      margin-bottom: 15px;
      li {
        border: 0;
        &:hover {
          background: $color_contrast_blue;
        }
        &.active a {
          background: $color_contrast_blue;
          color: white;
        }
        a {
          padding: 5px 25px;
          border: 0;
          background: none;
          color: $color_contrast_blue;
          &:hover {
            color: white;
          }
        }
      }
    }
  }
  ul.checkbox-list {
    overflow: hidden;
    margin-top: 20px;
    // padding: 10px;
  }
  ul.checkbox-list li {
    position: relative;
    margin: 0;
    padding: 10px 15px 10px;
    width: 100%;
    border-bottom: $border_little-grey;
    background: white;
    &:first-child {
      border-top: $border_little-grey;
    }
    .var {
      margin-left: 23px;
    }
    &:hover {
      background: lighten($color_bg_main, 25%);
    }
    span {
      position: relative;
      top: 2px;
    }
    input {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
    img {
      margin-right: 4px;
      max-width: 20px;
    }
  }
  ul.checkbox-list li[data-toggle='modal'] span {
    color: $color_contrast_blue;
  }

  .container-fluid:last-child {
    border-top: 1px solid $color_contrast_green;
  }
  // LEFT PART
  .col-md-9 {
    ul.list-top li.child {
      position: relative;
      display: inline-block;
      margin: 3px;
      width: 32%;
      @media screen and (max-width: $mobile) {
        display: block;
        width: 100%;
      }
      .glyphicon-img {
        position: absolute;
        top: 10px;
        left: 10px;
        color: $color_grey_blue;
        pointer-events: none;
        &.caret {
          top: 15px;
          right: 10px;
          left: auto;
        }
      }
      select:not(.no-form) {
        padding: 0 30px;

        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
      }
    }
    label {
      margin-right: 25px;
      @media screen and (max-width: $mobile) {
        display: block;
      }
    }
    button.black {
      margin-left: 10px;
      width: 100%;
      border-radius: 5px;
      background: black!important;
      color: white;
      text-align: center;
    }
    h3 {
      font-size: 1.6em;
      & > span {
        margin-top: 10px;
        font-weight: normal;
        font-size: 0.6em;
        .glyphicon {
          color: #c25903;
        }
      }
    }
    input[type='text'] {
      width: 100%;
      height: 40px;
    }
    label.bold {
      width: 100%;
      text-align: left;
      font-weight: bold;
      span {
        vertical-align: bottom;
      }
    }
    input[type='checkbox'] {
      margin-top: 0;
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    textarea {
      margin-bottom: 20px!important;
      width: 100%;
      height: 100px;
      border: $border_little-grey;
      border-radius: 5px;
      resize: vertical;
    }
    .panel {
      margin-top: 30px;
    }
    thead {
      background: black;
      color: white;
    }
    tbody {
      text-align: center;
    }
    .alert {
      padding: 0;
      ul li {
        display: inline-block;
        width: 30%;
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
      .panel-body {
        padding: 0 15px;
      }
      .panel-body > h3 {
        margin: 0;
      }
    }
    .alert-list {
      // margin-bottom: 40px;
      .panel {
        margin: 0;
      }
    }
    .panel-heading {
      color: $color_contrast_blue;
    }
    .button-green {
      position: relative;
      bottom: 15px;
      margin: 10px 0;
      padding: 10px 35px;
      border-radius: 5px;
      background: $color_contrast_green;
      &:hover {
        background: lighten($color_contrast_green,25%);
      }
    }
    .button-top {
      position: relative;
      bottom: 15px;
      margin: 10px 0;
      padding: 10px 35px;
      border-radius: 5px;
      background: $color_white;
      &:hover {
        background: lighten($color_contrast_green,25%);
      }
    }
    button.pourcent {
      margin-left: 10px;
      border: none;
      border-radius: 5px;
      background: $color_contrast_green;
      color: white;
    }
    .green-link {
      padding: 10px;
      color: $color_contrast_green;
      text-decoration: underline;
    }
  }
  // RIGHT PART
  .col-md-3 {
    .well {
      margin-top: 25px;
      .well {
        margin: 0;
        padding: 10px;
      }
    }
    button.first-button {
      display: block;
      margin-bottom: 15px;
      width: 100%;
      border-radius: 5px;
      div {
        float: left;
      }
      div.recruteur {
        width: 70%;
        text-align: left;
        text-transform: uppercase;
      }
      .glyphicon {
        position: relative;
        top: 4px;
        left: 5px;
        padding: 5px;
        border: 3px solid white;
        border-radius: 50%;
      }
    }
    .profile-pic {
      text-align: center;
      img {
        max-width: 350px;
        width: 100%;
        border-radius: 5px;
      }
      a {
        position: relative;
        bottom: 30px;
        padding: 2px 10px 2px 5px;
        background: black;
        color: white;
      }
    }
    h5 {
      margin-top: 25px;
      &.km-slider {
        color: $color_contrast_blue;
      }
    }
    a.edit-profile {
      position: relative;
      top: 10px;
      display: block;
      margin: 0 auto;
      border: $border_little-grey;
      border-radius: 5px;
      background: white;
      color: black;
      text-align: center;
      font-weight: 800;
      &:hover {
        background: #f1f1f1;
      }
    }
    ul.media {
      img.media-object {
        width: 50px;
      }
      li {
        margin-top: 0;
        padding-top: 15px;
        border-top: $border_little-grey;
      }
    }
    .menu-filter a {
      margin-left: 0;
      border: $border_little-grey;
      background: white;
      color: $color_grey_blue;
    }
  }
  .experience {
    padding-bottom: 15px;
  }
  a.green-link {
    padding-top: 5px;
    color: $color_contrast_green;
    text-decoration: underline;
    font-size: 15px;
    .glyphicon {
      margin-left: 5px;
    }
  }
}
